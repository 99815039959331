import { distinctUntilChanged } from 'rxjs/operators';
//Angular
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
//Models
import { Store } from "../../../models/models.model";
//Services
import { AerolineasArgentinasService } from 'services/aerolineas-argentinas.service';
import { AuthenticationService } from 'services/authentication.service';
import { RelationService } from 'services/model.service';
import { CustomerService } from 'services/customer.service';
import { ProfileService } from 'services/account/profile.service';
import { PointsService } from 'services/points.service';
import { StoreService } from 'services/store.service';
import { MacroService } from 'services/macro.service';
import { UrlService } from 'services/url.service';
//Other
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { MktService } from '../../new-core/core/services/mkt.service';
import { filter, first, tap } from 'rxjs/operators';

@Component({
  selector: 'app-aerolineas-argentinas',
  templateUrl: './aerolineas-argentinas.component.html',
  styleUrls: ['./aerolineas-argentinas.component.sass'],
  encapsulation: ViewEncapsulation.None
})

export class AerolineasArgentinasComponent implements OnInit, OnDestroy {
  confirmSelectionGroup: FormGroup;
  selectMilesGroup: FormGroup;
  transactionGroup: FormGroup;
  macroSession: Subscription;

  transaction: boolean;
  processData: boolean;
  isLoggedIn: boolean;
  externalLogIn: boolean;
  buttonLoader: boolean;
  formValid: boolean;
  multiplo = true;

  milesEquivalence: number;
  pointsEquivalence: number;
  availablePoints: number;

  docTypes = [
    {slug: 'DNI', value: 'DNI'},
    {slug: 'PASAPORTE', value: 'PAS'},
    {slug: 'PASAJERO FRECUENTE', value: 'SOC'}
  ];

  fecha: Date;

  error: string;
  email: string;
  socio: string;
  nombre: string;
  documento: string;
  operacion: string;
  puntosString = "PUNTO";
  milesString = 'MILLA'
  dateRange: boolean = false;

  public store: Store;
  storeId: number;

  close = false;


  constructor(private router: Router,
              private urlService: UrlService,
              private _formBuilder: FormBuilder,
              private storeService: StoreService,
              private macroService: MacroService,
              private _mktService: MktService,
              private customerService: CustomerService,
              private profileService: ProfileService,
              private pointsService: PointsService,
              private relationService: RelationService,
              private authenticationService: AuthenticationService,
              private aerolineasArgentinasService: AerolineasArgentinasService) {

                if( !localStorage.getItem('truchadaRelaoded')){

                  localStorage.setItem('truchadaRelaoded', 'true')
                  window.location.reload();

                }
              }

  ngOnInit() {

    this.checkHour();




    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.externalLogIn = this.authenticationService.isExternalLogin();
    this.subscriber();
    this.processData = false;
    this.formsGenerator();
    this._mktService.setMetaTags('Aerolineas Argentinas');

    if (this.isLoggedIn && environment.name === 'Macro') {

      (!this.macroService.isValidSession()) ? this.router.navigate(['session-expired']) : null;

      this.profileService.show().subscribe(
        (response) => {
          (response.temporary_email || !response.doc_number) ? this.macroService.updateProfile() : null ;
        }
      );

      this.macroSession = this.macroService.sessionStatus().subscribe(
        (response) => {
          (!response) ? this.router.navigate(['session-expired']) : null;
        }
      )
    }

  }

  subscriber(){
    this.storeService.currentStore();
    this.storeService.getStore().subscribe( res => {
      this.store = res;
      this.storeId = res.id;
      this.milesEquivalence = this.store.miles_equivalent;
      this.pointsEquivalence = this.store.points_equivalent;

    });

    this.availablePoints = +this.pointsService.getPoints()

    this.customerService.customerUpdate.subscribe(
      (value: boolean) => {
        if (value) this.getParams();
    });
  }

  plurals(word: string, quantity: number){
    return quantity > 1 ? word+'S' : word;
  }

  formsGenerator() {
    this.selectMilesGroup = this._formBuilder.group({
      'selectPoints': new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[1-9][0-9]*$/),
        Validators.max(+this.pointsService.getPoints()),
        Validators.min(this.milesEquivalence) // EDIT MIN INPUT MACRO
      ]),
      'selectDocType': new FormControl(this.docTypes[0].value, [
        Validators.required
      ]),
      'selectAerolineasPlus': new FormControl(null, [
        Validators.required
      ])
    });
    this.confirmSelectionGroup = this._formBuilder.group({
      'confirmEmail': new FormControl(null, [
        Validators.required,
        Validators.email
      ])
    });

    this.selectMilesGroup.valueChanges.subscribe(() => {
      this.formValid = this.selectMilesGroup.valid;
    })
  }

  getPoints() {
    return this.selectMilesGroup.getRawValue().selectPoints;
  }

  getDocType() {
    return this.selectMilesGroup.getRawValue().selectDocType;
  }

  getDocNumber() {
    return this.selectMilesGroup.getRawValue().selectAerolineasPlus;
  }

  getEmail() {
    return this.confirmSelectionGroup.getRawValue().confirmEmail;
  }

  pointsToMiles(): number {

    return Math.floor(
      this.getPoints() > 0
        ? (this.getPoints() / this.pointsEquivalence) * this.milesEquivalence
        : 0
      );
  }

  getParams() {
    const params = {
      id_cobis: +this.macroService.getCobis(),
      docType: this.getDocType(),
      docNumber: this.getDocNumber(),
      email: this.getEmail(),
      points: +this.getPoints(),
      store_id: this.storeId,
    }
    return params;
  }

  checkHour(){
    let date = new Date();
    let hour = date.getHours();

    if( Number( hour ) <= 6 ) {
      this.close = true;
    }else{
      this.close = false;
    }
  }

  async userValidator(stepper: MatStepper) {
    this.buttonLoader = true;
    this.error = null;
    if (this.isLoggedIn) {
      await this.aerolineasArgentinasService.getClient(this.getParams()).subscribe(
        (response) => {
          if (response[0].lv_cod_error == 1){
            this.nombre = response[0].lv_nombre + ' ' + response[0].lv_apellido;
            this.socio = response[0].membership_number;
            switch(this.getDocType()){
              case 'SOC': {
                this.documento = response[0].lv_nume_doc;
                break;
              }
              case 'DNI': {
                this.documento = this.getDocNumber();
                break;
              }
              case 'PAS': {
                this.documento = this.getDocNumber();
                break;
              }
            }
            stepper.next();
          } else {
             this.error = response[0].lv_mensaje_error;
          }
          this.buttonLoader = false;
      }, (error) => {
        this.buttonLoader = false;
      });
    } else {
      this.macroService.redirectToLogin();
    }

  }

  getParamsAcreditMiles() {
    const params = {
      id_cobis: +this.macroService.getCobis(),
      docType: 'SOC',
      docNumber: this.socio,
      email: this.getEmail(),
      points: +this.getPoints(),
      store_id: this.storeId,
    }
    return params;
  }


  async acreditMiles(stepper: MatStepper) {

    if (this.confirmSelectionGroup.valid) {
      stepper.next();
      this.processData = false;
      await this.aerolineasArgentinasService.acredit(this.getParamsAcreditMiles()).subscribe(
        (response) => {
          this.processData = true;
          if(response.success){
            this.error = null;
            this.email = this.getEmail();
            this.fecha = new Date();
            this.operacion = response.order_id;
            this.pointsService.updatePoints(-this.getPoints())

            //Send event to Google Analytics
            this._mktService.collect('MILLAS-AEROLINEAS-PLUS', {millas_aerolineas_data: this.getParamsAcreditMiles()} )
          } else {
            this.error = response.message;
            this.processData = true
          }
        },
        (error) => {
          this.error = "No fue posible completar la transacción"
          this.processData = true
        }
      );
    }

  }

  restart(stepper: MatStepper){
    this.error = null;
    stepper.reset()
  }

  cleanInvalidCharacter(event: KeyboardEvent){

    if (['.',',','e','E', '+', '-'].includes(event.key)){

      event.preventDefault();

    }

    const valuePoints = String(this.selectMilesGroup.get('selectPoints').value);

    if(valuePoints !== valuePoints.replace(/^(0+)/g, '')){
      event.preventDefault();
    }

  }

  pointsValidator(event?){

    if (this.selectMilesGroup.get('selectPoints').value < 0 || this.selectMilesGroup.get('selectPoints').value === 0) {
      this.selectMilesGroup.patchValue({'selectPoints': 0});
    }

    if (this.selectMilesGroup.get('selectPoints').value % this.pointsEquivalence != 0) {
      this.multiplo = false
      let miles = Math.floor(this.selectMilesGroup.get('selectPoints').value/this.pointsEquivalence)*this.pointsEquivalence;
      this.selectMilesGroup.patchValue({'selectPoints': miles});
    }else{
      this.multiplo = true;
    }

    if(this.isLoggedIn) {
      let value = this.getPoints();
      if (value >= this.availablePoints){
        if (event) event.preventDefault()
        this.selectMilesGroup.patchValue({'selectPoints': this.availablePoints});
      }
      if (value < 0){
        if (event) event.preventDefault()
        this.selectMilesGroup.patchValue({'selectPoints': 0});
      }
    }
  }
  ngOnDestroy() {
    if (this.macroSession) {
      this.macroSession.unsubscribe();
    }

    localStorage.removeItem('truchadaRelaoded');
  }

  backToForm(stepper: MatStepper) {
    stepper.previous();
  }

}
