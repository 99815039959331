import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.sass']
})
export class FaqsComponent implements OnInit {

  env = environment;
  option: number = 0;

  constructor(
    private router:Router
  ) { }

  ngOnInit() {
    if ( ["BancoNacion", "MiMoto", "EspecialesBNA"].includes(environment.name) ) {
      this.option = 0;
      return
    }

    if (environment.name == "Macro") {
      this.option = 1;
      return
    }

    this.router.navigate(["/"])
  }

}
