import { Component, OnInit, OnChanges } from '@angular/core';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Component({
    moduleId: '[app-customer]',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.sass'],
})

export class CustomerComponent implements OnInit, OnChanges {
  env: any;
  isRecovery = false;

  constructor(private router: Router) { }

  ngOnInit() {
    if (environment.name == "Macro") { this.router.navigate(['/']) }
    this.env = environment;
  }

  checkIsRecovery(e: boolean) {
    this.isRecovery = e;
  }

  checkTitle(): string {
    return this.isRecovery
        ? 'Recuperar Contraseña'
        : 'Ya soy cliente'
  }

  ngOnChanges() {
    this.checkTitle();
  }
}
