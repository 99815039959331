<swiper
      [spaceBetween]="1"
      [centeredSlides]="true"
      [autoplay]="{
        delay: 3000,
        disableOnInteraction: false
        }"
      [slidesPerView]="1"
      [slidesPerGroup]="1"
      [loop]="true"
      [resizeObserver]="true"
      [navigation]="!mobile ? true : false"
      >

  <ng-container *ngFor="let banner of getImages()">

    <ng-template swiperSlide class="swiperImg">
      <a *ngIf="banner.image || banner.mobile_image" (click)="navigate(banner)">
        <img class="coverImg" [src]="!mobile ? banner.image : banner.mobile_image" alt="covering"/>
      </a>
    </ng-template>

  </ng-container>

</swiper>
