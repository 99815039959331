import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { VariantCardComponent } from "./variant-card.component";
import { CoreModule } from '../../core/core.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    RouterModule
  ],
  exports: [
    VariantCardComponent,
  ],
  declarations: [
    VariantCardComponent
  ]
})

export class VariantCardModule { }
