<div class="content">
  <!-- Accodion FAQs -->
  <p style="text-align:center">
    <span style="font-family:Tahoma,Geneva,sans-serif">
      <span style="font-size:24px">
        <strong>Preguntas Frecuentes </strong>
      </span>
    </span>
  </p>

  <p>&nbsp;</p>

  <!-- BNA , BNA ESPECIALES, MI MOTO-->
  <div *ngIf="option === 0" class="accordion">


      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-1">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                    LOGUEO
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-1" class="collapse" aria-labelledby="group-1" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            No hace falta que tengas un usuario para poder comprar en Tienda BNA.
                            Simplemente tenés que ingresar a la página y seleccionar el producto que querés.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-2">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                    ¿CÓMO HACER UNA COMPRA EN TIENDA BNA?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-2" class="collapse" aria-labelledby="group-2" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Comprar en Tienda BNA es muy fácil! Solo tenés que seleccionar el producto que deseás:
                            <br>
                            <br>
                            - Primero, deberás verificar toda la información dentro de los apartados de descripción,
                            ficha técnica y envío (lo podrás encontrar en la parte inferior de la publicación).
                            Si todo es correcto, lo que tenés que hacer es seleccionar Comprar ahora:
                            <br>
                            <br>
                            <br>
                            <img src="https://i.postimg.cc/wxW5bK0x/faqs-bna1.png" alt="faqs">
                            <br>
                            <br>
                            <br>
                            - Luego, completás los datos de con tu información personal:
                            <br>
                            <br>
                            <br>
                            <img src="https://i.postimg.cc/SKqzxVDt/faqs-bna2.png" alt="faqs">
                            <br>
                            <br>
                            <br>
                            - Deberás seleccionar la opción de envío que prefieras:
                            <br>
                            <br>
                            <br>
                            <img src="https://i.postimg.cc/qRDtQr09/faqs-bna3.png" alt="faqs">
                            <br>
                            <br>
                            <br>
                            - Seguido, deberás seleccionar el método de pago que desees:
                            <br>
                            <br>
                            <br>
                            <img src="https://i.postimg.cc/L61qkGWb/faqs-bna4.png" alt="faqs">
                            <br>
                            <br>
                            <br>
                            Si la información de la orden que figura en el costado derecho de la pantalla es correcta,
                            para finalizar tu compra, deberás seleccionar la opción “Comprar”.
                            <br>
                            <br>
                            <br>
                            <img src="https://i.postimg.cc/tRLdHdQ8/faqs-bna5.png" alt="faqs">
                            <br>
                            <br>
                            <br>
                            Te enviaremos un mail con la confirmación de la compra y posteriormente otro correo con
                            los datos del envío. Por otro lado si lo que deseas es hacer múltiples compras te pedimos
                            que verifiques el apartado de <strong>Mi Carrito</strong>.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-3">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                  MI CARRITO
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-3" class="collapse" aria-labelledby="group-3" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Contamos con un Carrito al que podés ingresar los productos que querés y pagar todo junto.
                            Para esto, debés hacer lo siguiente:
                            <br>
                            <br>
                            <br>
                            1- Elegís tu producto (deberás verificar toda la información dentro de los apartados de
                            descripción, ficha técnica y envío)
                            <br>
                            <br>
                            2- Si todo es de tu agrado, seleccionas <strong>Agregar al carrito</strong>.
                            <br>
                            <br>
                            <br>
                            <img src="https://i.postimg.cc/wv1NCMW6/faqs-bna6.png" alt="faqs">
                            <br>
                            <br>
                            <br>
                            3- Luego elegir la opción de <strong>Seguir comprando</strong>.
                            <br>
                            <br>
                            <br>
                            <img src="https://i.postimg.cc/tCpTP7hY/faqs-bna7.png" alt="faqs">
                            <br>
                            <br>
                            <br>
                            Tenés que hacer esto con cada producto que quieras comprar. Cuando quieras finalizar la compra,
                            solo tenés que seleccionar el botón de <strong>Finalizar compra</strong>.
                            <br>
                            <br>
                            <br>
                            <img src="https://i.postimg.cc/SxKxT514/faqs-bna8.png" alt="faqs">
                            <br>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-4">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                    ¿CÓMO SACAR PRODUCTOS DE MI CARRITO?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-4" class="collapse" aria-labelledby="group-4" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Para sacar los productos de tu carrito debes seguir los siguientes pasos:
                            <br>
                            <br>
                            <br>
                            1- Ingresá a tu carrito, en la parte superior derecha de la página.
                            <br>
                            <br>
                            <br>
                            <img src="https://i.postimg.cc/5th9tt49/faqs-bna9.png" alt="faqs">
                            <br>
                            <br>
                            <br>
                            2- Se va a abrir una ventana con la lista de productos que seleccionaste.
                            <br>
                            <br>
                            <br>
                            <img src="https://i.postimg.cc/wvDqRdMX/faqs-bna10.png" alt="faqs">
                            <br>
                            <br>
                            <br>
                            3- Deberás seleccionar en el artículo que deseas eliminar de tu carrito, el icono del tachito:<img src="https://i.postimg.cc/1zsfQHHR/faqs-bna11.png" alt="faqs">
                            <br>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->


      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-5">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                    ¿CUÁLES SON LOS MÉTODOS DE PAGO?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-5" class="collapse" aria-labelledby="group-5" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Los medios de pago que tenemos actualmente son:
                            <br>
                            <br>
                            - Tarjeta de crédito y débito emitida por el Banco Nación.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->


      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-6">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
                  ¿QUÉ TIPO DE FACTURAS EFECTÚAN CON LA COMPRA?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-6" class="collapse" aria-labelledby="group-6" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Nuestro sitio está dirigido al consumidor final, por ese motivo emitimos solamente Factura B,
                            para todas las operaciones realizadas en la página.
                            <br>
                            <br>
                            Esta factura se envía de forma electrónica al mail con el cual se efectuó la compra y
                            también será entregada de forma manual cuando el producto llegue a destino.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-7">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-7" aria-expanded="false" aria-controls="collapse-7">
                  ¿POR DÓNDE ME ENVÍAN LA FACTURA?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-7" class="collapse" aria-labelledby="group-7" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Te enviaremos la factura correspondiente a tu compra al mail que dejes asentado,
                            por eso es muy importante que verifiques que todos tus datos sean correctos antes
                            de finalizar la operación y también podrá ser entregada  en formato papel cuando
                            el producto llegue a destino.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-8">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-8" aria-expanded="false" aria-controls="collapse-8">
                  AÚN NO HE RECIBIDO MI FACTURA
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-8" class="collapse" aria-labelledby="group-8" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Si aun no has recibido la factura via email, ni entregada con el producto en el domicilio,
                            por favor ponte en contacto con el comercio asociado con el realizaste la transacción
                            indicándole nombre del titular de la compra, el número de Orden el pedido y DNI para que
                            ellos puedan reenviarte ese comprobante.
                            Recuerda que podrás ver los datos del comercio asociado en el correo electrónico de
                            confirmación de compra que te llega una vez aprobado el pago.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-9">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-9" aria-expanded="false" aria-controls="collapse-9">
                    ¿CUÁNDO LLEGA MI COMPRA?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-9" class="collapse" aria-labelledby="group-9" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Cuando finalizas la compra se te enviará un email, el cual contendrá el número de Orden
                            del pedido. Por otro lado en las próximos 7 días hábiles el comercio asociado que hayas
                            elegido para operar cargará el numero de seguimiento para que puedas verificar el estado
                            del envío en los portales de seguimiento del correo que este último te indique.
                            Si no te fue enviado el número de seguimiento por favor ponte en contacto con el comercio
                            asociado con el realizaste la transacción indicándole nombre del titular de la compra,
                            el número de Orden del pedido y DNI para que ellos puedan indicarte y/o reenviarte esta
                            información.
                            Recuerda que podrás ver los datos del comercio asociado en el correo electrónico de
                            confirmación de compra que te llega una vez aprobado el pago.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-10">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-10" aria-expanded="false" aria-controls="collapse-10">
                    ¿CÓMO PUEDO CONFIRMAR EL COSTO DE MI ENVÍO?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-10" class="collapse" aria-labelledby="group-10" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            El costo de envío se calcula según el peso de la compra en relación con el destino
                            de entrega, podes ver el valor cuando ingresas los datos de tu domicilio, antes de
                            finalizar la compra o podrás calcular el precio del envío, a la hora de verificar
                            los datos de una publicación, verás en el margen derecho el siguiente recuadro:
                            <br>
                            <br>
                            <br>
                            <img src="https://i.postimg.cc/cJx4jJxr/faqs-bna12.png" alt="faqs">
                            <br>
                            <br>
                            <br>
                            Los diferentes tipos de resultados que podrás visualizar seleccionando <strong>Calcular</strong> son los siguientes:
                            - El proveedor se pondrá en contacto para coordinar la entrega y el costo de envío.
                            <br>
                            <br>
                            <br>
                            <img src="https://i.postimg.cc/c6Gdcxk1/faqs-bna13.png" alt="faqs">
                            <br>
                            <br>
                            <br>
                            Recuerda que aunque en el resumen de tu compra figure 0 en el monto de envío, es el
                            proveedor quien te informará dicho importe.
                            - El envío tiene un costo, el mismo varía dependiendo del proveedor
                            <br>
                            <br>
                            <br>
                            <img src="https://i.postimg.cc/3x7v8YrH/faqs-bna14.png" alt="faqs">
                            <br>
                            <br>
                            <br>
                            - El envío es gratuito
                            <br>
                            <br>
                            <br>
                            <img src="https://i.postimg.cc/WpwjRvyZ/faqs-bna15.png" alt="faqs">
                            <br>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-11">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-11" aria-expanded="false" aria-controls="collapse-11">
                  ¿CUÁNTO DEMORA EN LLEGAR EL ENVÍO A DOMICILIO?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-11" class="collapse" aria-labelledby="group-11" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            En Tienda BNA queremos que estés lo más informado acerca de tu envío, por eso es que cuando
                            tu pedido se encuentre despachado te enviaremos un mail con todos los datos del mismo.
                            <br>
                            <br>
                            - El tiempo de entrega es de 8 a 15 días hábiles para CABA, GBA y resto del país.
                            <br>
                            <br>
                            - Para muebles y línea blanca es de 7 a 10 días hábiles para CABA y GBA y de 10 a 14 días
                            hábiles para el interior del país.
                            <br>
                            <br>
                            Recuerda que de igual manera los tiempos de entrega pueden verse afectados debido a la
                            situación de público conocimiento.
                            <br>
                            <br>
                            Si ya cuentas con el numero de seguimiento podrás consultar en tiempo real el estado de
                            envío del pedido y los tiempos estimados de entrega, en caso contrario, donde no cuentes
                            aún con este número de guía podrás solicitárselo al comercio asociado con el que elegiste
                            operar.
                            <br>
                            <br>
                            Recuerda que podrás ver los datos del comercio asociado en el correo electrónico de
                            confirmación de compra que te llega una vez aprobado el pago.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-12">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-12" aria-expanded="false" aria-controls="collapse-12">
                    ¿QUÉ SIGNIFICA QUE MI COMPRA SERÁ DESPACHADA POR SEPARADO?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-12" class="collapse" aria-labelledby="group-12" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Con el fin de mejorar la calidad del servicio, Tienda BNA  cuenta con diferentes
                            comercios asociados para asegurarte la mayor variedad y calidad de productos, por
                            lo cual si compraste varios productos es probable que tu compra sea despachada por
                            separado directamente desde el deposito de cada comercio interviniente para acelerar
                            los tiempos y optimizar costos.De todas maneras siempre se te  envirá el  número de
                            seguimiento correspondiente de cada producto para que puedas rastrearlo.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-13">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-13" aria-expanded="false" aria-controls="collapse-13">
                    SI NO ESTOY A LA HORA DE RECIBIR EL PRODUCTO, ¿LO PUEDE RECIBIR ALGUIEN MÁS?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-13" class="collapse" aria-labelledby="group-13" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            El servicio de correo contempla la entrega a una persona mayor de 18 años con
                            documentación que acredite su identidad en el domicilio completado por el
                            Usuario al momento de la compra, sin necesidad de autorización expresa por
                            parte del mismo.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-14">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-14" aria-expanded="false" aria-controls="collapse-14">
                  ¿QUÉ PASA SI NO HAY NADIE EN MI DOMICILIO CUANDO LLEGA EL ENVÍO?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-14" class="collapse" aria-labelledby="group-14" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            El correo en la mayoría de los casos pasa en dos oportunidades por tu domicilio
                            para efectuar la entrega. Si cuentas con el número de seguimiento podrás contactarte
                            con el servicio de envío
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-15">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-15" aria-expanded="false" aria-controls="collapse-15">
                    COMPRÉ UN PRODUCTO EN EL QUE LA ENTREGA SE COORDINA CON EL PROVEEDOR, ¿QUÉ SIGNIFICA?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-15" class="collapse" aria-labelledby="group-15" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Significa que el producto se entrega con una logística propia del comercio asociado y no mediante un
                            operador tercerizado.
                            Para estos casos el comercio se pondrá en contacto con vos para coordinar la entrega o bien,
                            te enviará por correo el detalle de como se procederá para el envío.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-16">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-16" aria-expanded="false" aria-controls="collapse-16">
                    ¿CUÁL ES EL PLAZO DE ENTREGA POR EL PROVEEDOR?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-16" class="collapse" aria-labelledby="group-16" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Las condiciones de entrega las podes ver en la publicación, abajo de las fotos
                            del producto en la parte de Envíos:
                            <br>
                            <br>
                            <br>
                            <img src="https://i.postimg.cc/wxW5bK0x/faqs-bna1.png" alt="faqs">
                            <br>
                            <br>
                            <br>
                            En caso de que no hayas recibido tu producto y el comercio asociado no te haya
                            bindado una respuesta con respecto al estado de envio de tu orden pasados los
                            15 dias habiles comunicate con nuestro equipo de Atención al Cliente ingresando <a href="https://www.tiendabna.com.ar/ayuda">acá</a>.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-17">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-17" aria-expanded="false" aria-controls="collapse-17">
                    COMPRÁ SEGURO EN TIENDA BNA
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-17" class="collapse" aria-labelledby="group-17" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            En Tienda BNA le damos mucha importancia a la transparencia de nuestras operaciones
                            y cuidamos tu experiencia de compra y seguridad. Nuestro sitio sigue los más altos
                            estándares y toda la información ingresada se mantiene de manera estrictamente confidencial.
                            <br>
                            <br>
                            Para garantizar la seguridad en los pagos usamos la tecnología SPS de DECIDIR, perteneciente
                            a Prisma.
                            <br>
                            <br>
                            Con respecto a los datos de tu tarjeta de crédito nuestros sistemas se rigen bajo las normas
                            PCI DSS (Estándar de Seguridad de Datos para la Industria de Tarjeta de Pago).
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-18">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-18" aria-expanded="false" aria-controls="collapse-18">
                    ¿CUÁLES SON LAS MEDIDAS DE SEGURIDAD?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-18" class="collapse" aria-labelledby="group-18" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Contamos con la tecnología SSL (Secure Sockets Layer) que asegura tanto la autenticidad
                            de nuestro sitio como el cifrado de toda la información que completó en el sitio. Cada
                            vez que comprás en Tienda BNA y completas información de carácter personal, sin importar
                            dónde te encuentres, tu navegador se conecta a nuestro sitio a través del protocolo SSL
                            que acredita que estás realmente en Tienda BNA y en nuestros servidores (lo cual notarás
                            con la aparición del código HTTPS en la barra de direcciones de tu navegador).
                            <br>
                            <br>
                            De esta forma se establece un método de cifrado de tu información y una clave de sesión
                            única. Esta tecnología permite que información personal, como nombre, dirección y datos
                            de tarjeta de crédito sean codificadas antes de tu transferencia para que no pueda ser
                            leída cuando viaja a través de Internet. Todos los certificados SSL se crean para un
                            servidor particular, en un dominio específico y para una entidad comercial comprobada.
                            Tienda BNA cuenta con un servidor certificado.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-19">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-19" aria-expanded="false" aria-controls="collapse-19">
                    ¿POR QUÉ ES SEGURO?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-19" class="collapse" aria-labelledby="group-19" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            - Porque los datos que usted ingresa viajan encriptados entre su computadora y PRISMA,
                            gracias a la tecnología de certificados digitales. Para esto verifique que su navegador
                            le indique la presencia del certificado digital (el candado).
                            <br>
                            <br>
                            - Porque los datos de su tarjeta son tratados siguiendo los más altos estándares de seguridad
                            exigidos por las normas PCI DSS (Estándar de Seguridad de Datos para la Industria de Tarjeta
                            de Pago).
                            <br>
                            <br>
                            - Porque nuestro sistema se encuentra auditado por los medios de pago para asegurar el estricto
                            cumplimiento de las medidas de seguridad.
                            <br>
                            <br>
                            - Porque usted no se ve obligado a brindar los datos sensibles de su tarjeta a un desconocido.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-20">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-20" aria-expanded="false" aria-controls="collapse-20">
                    TENGO DUDAS SOBRE LAS MEDIDAS DE ALGÚN PRODUCTO
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-20" class="collapse" aria-labelledby="group-20" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            En Tienda BNA queremos que tengas la mejor experiencia de compra, por eso informamos
                            las características de los productos en la página de descripción de cada uno.
                            Así podrás saber con exactitud lo que estás comprando.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-21">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-21" aria-expanded="false" aria-controls="collapse-21">
                    YA NO ENCUENTRO UN PRODUCTO QUE ESTABA PUBLICADO, ¿QUÉ PUEDO HACER?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-21" class="collapse" aria-labelledby="group-21" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Para comenzar, utiliza nuestro buscador para verificar que no haya modificado
                            el nombre de la publicación. De todas formas es posible que el producto se
                            encuentre agotado, por lo que por el momento no lo podrás comprar.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-22">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-22" aria-expanded="false" aria-controls="collapse-22">
                    TENÍA UN PRODUCTO AGREGADO EN MI CARRITO Y YA NO FIGURA
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-22" class="collapse" aria-labelledby="group-22" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Puede haber sucedido que alguno de los productos agregados a tu compra ya no
                            tengan disponibilidad por estar agotados.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-23">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-23" aria-expanded="false" aria-controls="collapse-23">
                    ¿CÓMO FUNCIONAN LAS GARANTÍAS DE UN PRODUCTO?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-23" class="collapse" aria-labelledby="group-23" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          Todos los productos cuentan con garantía de fábrica, en la publicación de cada uno podes
                          ver los plazos dependiendo del artículo, para poder utilizarla debes asistir al servicio
                          técnico oficial de la marca con la factura de compra.
                        </span>
                      </span>
                    </span>
                </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-24">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-24" aria-expanded="false" aria-controls="collapse-24">
                    ¿CUÁL ES LA POLÍTICA DE CAMBIOS Y DEVOLUCIONES DE TIENDA BNA ?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-24" class="collapse" aria-labelledby="group-24" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            En Tienda BNA queremos que tu experiencia sea la mejor, por eso si el producto que compraste
                            tuvo algún inconveniente tienes que contactarte con el comercio asociado con el que realizaste
                            la transacción dentro de los 10 días posteriores a la entrega para que  puedan gestionarte el
                            cambio, esta gestión no tiene costo extra.
                            <br>
                            <br>
                            Lo mismo aplica el producto que compraste no te gustó o te equivocaste en el talle o color,
                            contactate con el comercio del mismo modo para poder gestionar la devolución.
                            <br>
                            <br>
                            Los motivos por los cuales podes gestionar el cambio o la devolución de un producto son los
                            siguientes:
                            <br>
                            <br>
                            - Producto defectuoso
                            - El tamaño no es correcto
                            - Me equivoqué en la compra
                            - El producto no era lo que yo esperaba
                            - Recibí algo que no pedí (o un producto diferente)
                            <br>
                            <br>
                            No se aceptan cambios y devoluciones de productos usados o sin sus accesorios o envoltorio
                            original (cajas, plásticos, manuales, etc). Los cambios se encuentran sujetos a disponibilidad
                            de stock. Primero deberás devolver el producto original para recibir el nuevo producto o el
                            reembolso correspondiente.
                            <br>
                            <br>
                            Pasados los 10 días posteriores a la recepción o retiro del producto, Si el producto tiene
                            garantía, puedes contactarte con la misma para efectuar la reparación o cambio.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-25">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-25" aria-expanded="false" aria-controls="collapse-25">
                    CÓMO FUNCIONAN LAS DEVOLUCIONES
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-25" class="collapse" aria-labelledby="group-25" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            En caso que debamos proceder con una devolución por alguno de los motivos anteriormente
                            mencionados, la misma se realizará a través de una rembolso en la tarjeta de crédito
                            que utilizaste en el momento de la creación del pedido.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-26">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-26" aria-expanded="false" aria-controls="collapse-26">
                    ¿CUÁNTO DEMORA EN ACREDITARSE EL IMPORTE EN MI TARJETA?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-26" class="collapse" aria-labelledby="group-26" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Si abonaste con tarjeta de crédito el reembolso lo podes ver reflejado dentro de las
                            siguientes 72 hs hábiles de haber sido efectuada la cancelación, dependiendo de los
                            tiempos del banco.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
          <div class="card-header p-0" id="group-27">
              <h2 class="mb-0">
                  <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-27" aria-expanded="false" aria-controls="collapse-27">
                    ¿CÓMO HAGO USO DE LA GARANTÍA DE MI PRODUCTO?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                  </button>
              </h2>
          </div>

          <div id="collapse-27" class="collapse" aria-labelledby="group-27" >
              <div class="card-body">
                  <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                    <span style="font-size:12px">
                      <span style="background-color:white">
                        <span style="font-family:Tahoma,Geneva,sans-serif">
                          <span style="color:#333333">
                            Todos nuestros productos cuentan con garantía, en la publicación de cada uno podes ver
                            los plazos dependiendo del artículo, para poder utilizarla debes contactarte con nosotros,
                            una vez te brindamos los datos debes contactarte con el service que te informamos con la
                            factura en mano.
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
              </div>
          </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
        <div class="card-header p-0" id="group-28">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-28" aria-expanded="false" aria-controls="collapse-28">
                  CÓMO VENDER EN TIENDA BNA
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                    </button>
            </h2>
        </div>

        <div id="collapse-28" class="collapse" aria-labelledby="group-28" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                  <span style="font-size:12px">
                    <span style="background-color:white">
                      <span style="font-family:Tahoma,Geneva,sans-serif">
                        Si estas interesado en formar parte de la red de comercios asociados de Tienda BNA
                        puedes ponerte en contacto con nuestra área comercial a través del siguiente <a href="https://docs.google.com/forms/d/e/1FAIpQLSedesH8UEwKldquzpL45lCS7FJuLfG88pW0BY-8JJHzbtzfaA/viewform">formulario</a>
                      </span>
                    </span>
                  </span>
              </p>
            </div>
        </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
        <div class="card-header p-0" id="group-29">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-29" aria-expanded="false" aria-controls="collapse-29">
                  ¿CÓMO FUNCIONA LA CONTRATACIÓN DE SEGUROS DESDE TIENDA BNA?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                    </button>
            </h2>
        </div>

        <div id="collapse-29" class="collapse" aria-labelledby="group-29" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                  <span style="font-size:12px">
                    <span style="background-color:white">
                      <span style="font-family:Tahoma,Geneva,sans-serif">
                        Podés contratar tu seguro todo riesgo para productos seleccionados de Tienda BNA. Luego de agregar tu producto al carrito y presionar “Finalizar Compra” podrás seleccionar la opción “Añadir Seguro”. Allí verás el monto por mes del valor del seguro. Solo podrás asegurar productos desde el proceso de checkout, no es posible asegurar otros productos adquiridos anteriormente o que no sean de la tienda.<br>
                        Si seleccionaste asegurar tu producto, deberás completar los datos solicitados y luego te llegarán por mail los datos de la poliza.<br>
                        Tu seguró estará vigente a partir del quinto día hábil desde realizada la compra. Si necesitás utilizarlo deberás contactarte al al 0800-888-9908 para denunciar el siniestro.<br>
                        La vigencia del seguro es de 12 meses y tiene renovación automática.<br>
                        En caso que desees dar de baja el seguro deberás contactarte a 0800-888-9908.<br>
                      </span>
                    </span>
                  </span>
              </p>
            </div>
        </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
        <div class="card-header p-0" id="group-30">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-30" aria-expanded="false" aria-controls="collapse-30">
                  NECESITO CONTACTARME CON EL VENDEDOR
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-30" class="collapse" aria-labelledby="group-30" >
            <div class="card-body">

              <p class="mb-2" style="line-height: 23px;">
                Si necesitas contactarte con el vendedor de tu producto haz clic <a routerLink="/datos-seller" class="text-info" target="_blank">AQUI</a> para ver sus datos de contacto. Si no conoces el vendedor, podrás ver su nombre en la página del producto que desees.
              </p>

            </div>
        </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
        <div class="card-header p-0" id="group-31">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-31" aria-expanded="false" aria-controls="collapse-31">
                  DONDE CONSEGUIR MIS CUPONES
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-31" class="collapse" aria-labelledby="group-31" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                  <span style="font-size:12px">
                    <span style="background-color:white">
                      <span style="font-family:Tahoma,Geneva,sans-serif">
                        Podés canjear cupones para la Tienda en el Programa de Beneficios del Banco Nación. Podes hacerlo canjeando tus puntos acumulados a través de <a href="https://www.bna.com.ar/Downloads/InstructivoBNAmasCLIENTES.pdf">BNA+</a> o también en forma telefónica, comunicándote al 0810 666 1515 de lunes a viernes de 8 a 20 hs.<br>
                      </span>
                    </span>
                  </span>
              </p>
            </div>
        </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
        <div class="card-header p-0" id="group-31">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-31" aria-expanded="false" aria-controls="collapse-31">
                  AYUDA
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-31" class="collapse" aria-labelledby="group-31" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                  <span style="font-size:12px">
                    <span style="background-color:white">
                      <span style="font-family:Tahoma,Geneva,sans-serif">
                        Si necesitas contactarte con nosotros por favor completá el formulario para que nuestro equipo de atención al cliente pueda ayudarte o llamanos al 810 4444 500 de lunes a sábados de 08 a 20 hs.<br>
                      </span>
                    </span>
                  </span>
              </p>
            </div>
        </div>
      </div>
      <!-- End Section -->

  </div>

  <!-- MACRO -->
  <div *ngIf="option === 1" class="accordion">
    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-1">
          <h2 class="mb-0">
              <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
              1. &iquest;Qu&eacute; es Macro Premia?
              <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
              </button>
          </h2>
      </div>

      <div id="collapse-1" class="collapse" aria-labelledby="group-1" >
          <div class="card-body">
              <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Es una propuesta exclusiva para los clientes de Banco Macro, en la que podr&aacute;n adquirir una amplia gama de productos, servicios y/o beneficios, mediante el canje de puntos Macro Premia o mediante la adquisici&oacute;n de los mismos bajo la modalidad S&oacute;lo Pesos, o un mix entre estas, denominado como Puntos m&aacute;s Pesos variable.</span></span></span></span></p>
          </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-2">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                  2. &iquest;C&oacute;mo ingreso a Macro Premia?
                  <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-2" class="collapse" aria-labelledby="group-2" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Para acceder a Macro Premia ser&aacute; requisito ingresar a trav&eacute;s de Banca Internet o App Macro. Para ello los clientes deber&aacute;n contar con un usuario y una clave para poder ingresar a la Web.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-3">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                3. &iquest;Qui&eacute;nes pueden participar de Macro Premia?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-3" class="collapse" aria-labelledby="group-3" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Todos los clientes que est&eacute;n adheridos al programa Macro Premia participar&aacute;n acumulando puntos por sus consumos con sus tarjetas de cr&eacute;dito Macro. Los clientes Aerol&iacute;neas Plus, no acumulan puntos por consumo, pero pueden adquirir la oferta disponible en la modalidad Solo Pesos.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->


    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-4">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                4. &iquest;C&oacute;mo acumulo puntos?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-4" class="collapse" aria-labelledby="group-4" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Todos los clientes adheridos al programa acumulan Puntos Macro Premia a trav&eacute;s de consumos realizados en la Rep&uacute;blica Argentina y en el exterior, tanto en $ como en U$S en las tarjetas de cr&eacute;dito Macro.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->


    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-5">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                5. &iquest;Cu&aacute;ntos puntos se acumulan por consumo?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-5" class="collapse" aria-labelledby="group-5" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Forma de c&aacute;lculo de Puntos Macro Premia: Por cada $ 600 (seiscientos pesos) de consumo acumula un Punto Macro Premia. Por cada US$ 600 (seiscientos d&oacute;lares estadounidenses) de consumo se acumula un Punto Macro Premia. En ning&uacute;n caso se computar&aacute;n fracciones de Puntos Macro Premia. Los gastos de D&eacute;bitos Autom&aacute;ticos ser&aacute;n transformados a Puntos Macro Premia por el total de su importe.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->


    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-6">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
                6. &iquest;D&oacute;nde puedo ver los puntos que tengo?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-6" class="collapse" aria-labelledby="group-6" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Para consultar los puntos disponibles es necesario ingresar a Banca Internet o App Macro con su usuario y contrase&ntilde;a.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-7">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-7" aria-expanded="false" aria-controls="collapse-7">
                7. &iquest;Los puntos tienen vencimiento?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-7" class="collapse" aria-labelledby="group-7" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">La vigencia de los Puntos Macro Premia es de dos (2) a&ntilde;os m&oacute;viles contados a partir del mes de consumo. Adicionalmente cumplidos los 6 meses de inactividad en el programa Macro Premia, se vencer&aacute; la totalidad de puntos acumulados, considerando como inactividad tanto la no acreditaci&oacute;n de puntos (por consumos o acciones especiales), como la no redenci&oacute;n (canje de puntos o compra en modalidad &ldquo;Solo Pesos&rdquo;).</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-8">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-8" aria-expanded="false" aria-controls="collapse-8">
                8. &iquest;C&oacute;mo suman las tarjetas adicionales?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-8" class="collapse" aria-labelledby="group-8" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Las tarjetas adicionales sumar&aacute;n Puntos Macro Premia en la cuenta del titular de la Tarjeta de Cr&eacute;dito que se est&aacute; utilizando. Los productos, beneficios y/o servicios s&oacute;lo podr&aacute;n ser solicitados por los titulares y ser&aacute;n entregados en el domicilio informado por el titular.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-9">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-9" aria-expanded="false" aria-controls="collapse-9">
                9. &iquest;Puedo transferir los puntos a otra cuenta Macro?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-9" class="collapse" aria-labelledby="group-9" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Los Puntos Macro Premia no son transferibles a ninguna otra cuenta del programa, ni a ninguna persona o entidad bajo ning&uacute;n t&iacute;tulo o causa (fallecimiento, incapacidad, disoluci&oacute;n de la sociedad conyugal, etc.). Los Puntos Macro Premia tampoco podr&aacute;n ser canjeados por dinero.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-10">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-10" aria-expanded="false" aria-controls="collapse-10">
                10. &iquest;Qu&eacute; productos, servicios o beneficios ofrece Macro Premia?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-10" class="collapse" aria-labelledby="group-10" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">El programa ofrece una gran variedad de propuestas, entre las que se destacan Productos, Ahorros en compras con Tarjeta de Cr&eacute;dito, Recargas para Celulares, Recargas en tarjeta SUBE, Millas Aerol&iacute;neas Plus, Viajes en Despegar.com, Premios Instant&aacute;neos, Entradas de Cine, Gift Cards, Experiencias, entradas para espect&aacute;culos, entre otras opciones.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-11">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-11" aria-expanded="false" aria-controls="collapse-11">
                11. &iquest;Puedo adquirir productos si no tengo puntos? (modalidades de canje)
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-11" class="collapse" aria-labelledby="group-11" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Los clientes del banco que tengan acceso a Banca Internet o App Macro podr&aacute;n adquirir los productos, servicios y/o beneficios que est&eacute;n disponibles bajo la modalidad Solo Pesos.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-12">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-12" aria-expanded="false" aria-controls="collapse-12">
                12. &iquest;Cu&aacute;les son las modalidades para adquirir productos, servicios o beneficios de Macro Premia?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-12" class="collapse" aria-labelledby="group-12" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Los productos, beneficios y/o servicios de Macro Premia pueden adquirirse bajo las siguientes modalidades: &ndash; Solo Puntos: Es necesario contar con la totalidad de puntos requeridos para poder adquirir el producto, servicio o beneficio seleccionado. &ndash; Puntos m&aacute;s Pesos Variable: Productos, servicios y/o beneficios que se pueden adquirir mediante el canje de puntos y el pago de una parte del mismo en pesos, con las tarjetas de cr&eacute;dito y o d&eacute;bito emitidas por el banco, permitiendo elegir, con tope en sus puntos disponibles, qu&eacute; proporci&oacute;n del producto abonar con puntos, y qu&eacute; proporci&oacute;n abonar en pesos. &ndash; Solo Pesos: No es necesario contar con puntos. La totalidad de pesos requerida para la adquisici&oacute;n del producto, servicio o beneficio se abona con las tarjetas de cr&eacute;dito y o d&eacute;bito emitidas por el banco.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-13">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-13" aria-expanded="false" aria-controls="collapse-13">
                13. &iquest;Los clientes adheridos Aerol&iacute;neas Plus en Banco Macro participan del programa?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-13" class="collapse" aria-labelledby="group-13" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Los clientes Aerol&iacute;neas Plus pueden ingresar al programa y adquirir los productos, servicios y/o beneficios disponibles mediante la modalidad Full Pesos, abonando con las tarjetas de cr&eacute;dito y o d&eacute;bito emitidas por Banco Macro. En caso de contar con puntos disponibles por haber participado en el programa o por alguna acci&oacute;n especial, podr&aacute; canjearlos por la oferta disponible en el momento del ingreso.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-14">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-14" aria-expanded="false" aria-controls="collapse-14">
                14. &iquest;Qu&eacute; medios de pago puedo utilizar para adquirir productos y servicios de Macro Premia?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-14" class="collapse" aria-labelledby="group-14" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Todos los productos, servicios y/o beneficios que est&eacute;n disponibles bajo la modalidad Puntos m&aacute;s Pesos variable y /o Solo Pesos podr&aacute;n ser abonados con las tarjetas de cr&eacute;dito y o d&eacute;bito emitidas por el Banco Macro.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-15">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-15" aria-expanded="false" aria-controls="collapse-15">
                15. &iquest;Cu&aacute;nto demora la entrega de los Productos solicitados?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-15" class="collapse" aria-labelledby="group-15" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">El producto solicitado ser&aacute; entregado por la empresa OCASA SOLUCIONES LOGISTICAS en el domicilio del cliente o en el que indique oportunamente dentro de los 15 d&iacute;as h&aacute;biles posteriores a la fecha en que ha realizado la misma. En el caso de que la empresa no pudiera entregar el premio, realizar&aacute; hasta 2 visitas en d&iacute;as correlativos, en distintos horarios cada uno. Luego de las mismas, ingresar&aacute; el producto no entregado al stock, a trav&eacute;s del sistema correspondiente, sobre tal situaci&oacute;n con el objeto de realizar la devoluci&oacute;n de Puntos Macro Premia y/o pesos en el medio de pago seleccionado por el cliente. La recepci&oacute;n de los productos la podr&aacute; realizar el titular u otra persona mayor a 14 a&ntilde;os, la cual deber&aacute; firmar el acuse de recibo correspondiente. El cliente podr&aacute; ser contactado v&iacute;a mail por OCASA para coordinar fecha y horario de entrega o para dar aviso de la devoluci&oacute;n del producto. Tambi&eacute;n se puede contactar telef&oacute;nicamente al 0810-555-7736 (PREMIA). Los Servicios que consisten en recarga celular / millas / viajes / premios instant&aacute;neos / entradas de cine / gift cards / experiencias / entradas a espect&aacute;culos / ahorros / cargas SUBE, ser&aacute;n enviados por mail o retirados personalmente seg&uacute;n corresponda, tal se detallar&aacute; debidamente en el sitio Macro Premia.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-16">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-16" aria-expanded="false" aria-controls="collapse-16">
                16. &iquest;Los Productos tienen costo de env&iacute;o?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-16" class="collapse" aria-labelledby="group-16" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">No hay costo de env&iacute;o.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-17">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-17" aria-expanded="false" aria-controls="collapse-17">
                17. &iquest;C&oacute;mo debo proceder en caso de tener alg&uacute;n inconveniente con el producto recibido?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-17" class="collapse" aria-labelledby="group-17" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">El cliente podr&aacute; contactarse con el Centro de Atenci&oacute;n Telef&oacute;nica al (0810-555-2355) o por Banca Internet con clave y usuario, dentro de los 10 (diez) d&iacute;as siguientes de haber recibido el premio si el mismo presentara fallas, roturas, errores, desperfectos o falencias para que el Banco gestione la reposici&oacute;n. Si el reclamo corresponde a fallas, mal funcionamiento, roturas, errores, desperfectos o falencias de productos de Electr&oacute;nica o Electrodom&eacute;sticos con posterioridad a los primeros 10 (diez) d&iacute;as de haber recibido el producto, el cliente podr&aacute; acudir a la garant&iacute;a que corresponda seg&uacute;n el fabricante/ marca del producto presentando su factura. La misma ser&aacute; enviada por mail luego de realizar la solicitud.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-18">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-18" aria-expanded="false" aria-controls="collapse-18">
                18. &iquest;C&oacute;mo realizo un canje de Ahorros?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-18" class="collapse" aria-labelledby="group-18" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Los Puntos Macro Premia pueden canjearse por Ahorros para ser utilizados en los comercios adheridos al programa. Para acceder al Ahorro, una vez canjeado, el cliente deber&aacute; acercarse al comercio en los d&iacute;as indicados y efectuar la compra con la tarjeta de cr&eacute;dito seleccionada. Podr&aacute; ver la bonificaci&oacute;n en el resumen posterior a la transacci&oacute;n o bien en el siguiente. El Ahorro es v&aacute;lido s&oacute;lo para una transacci&oacute;n, y no podr&aacute; acumularse m&aacute;s de un Ahorro canjeado en la misma transacci&oacute;n. El Ahorro se recibir&aacute; en la primera transacci&oacute;n que se realice, ya sea con la tarjeta Titular o con cualquiera de sus adicionales de la tarjeta seleccionada, en el comercio adherido contando con una vigencia de 30 d&iacute;as desde el momento del canje. El Ahorro aplicar&aacute; independientemente de la cantidad de cuotas en que se abone la compra y ser&aacute; acumulable con cualquier promoci&oacute;n vigente al momento del consumo del comercio o del Banco. Una vez realizado el canje, recibir&aacute; la confirmaci&oacute;n por mail. Los Ahorros no admiten cambios ni devoluciones. - &iquest;Tengo que esperar un voucher o confirmaci&oacute;n? Llegar&aacute; la confirmaci&oacute;n de la solicitud v&iacute;a email. - &iquest;Tengo que avisar al comercio que tengo un ahorro Macro premia para obtener mi descuento? No, no es necesario dar aviso al comercio.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-19">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-19" aria-expanded="false" aria-controls="collapse-19">
                19. &iquest;C&oacute;mo realizo un canje de Recargas?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-19" class="collapse" aria-labelledby="group-19" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">El canje de recargas disponibles en Macro Premia son exclusivamente para acreditar saldo en l&iacute;neas de celulares prepagas. En caso de querer canjear una recarga de celular, se completar&aacute;n los datos de la l&iacute;nea en la cual se desea realizar la recarga, y unos minutos despu&eacute;s de realizado el canje, recibir&aacute; un SMS con la confirmaci&oacute;n de la misma. Si se desea recargar DirecTV Prepago, se deber&aacute; informar el N&deg; de abonado al cual se desea realizar la recarga, y en los pr&oacute;ximos 30 minutos de realizado el canje se acreditar&aacute; la misma. - Puedo recargar el celular de otra persona? S&iacute;. Se establece el n&uacute;mero de l&iacute;nea al cual aplica el beneficio al momento de generar la solicitud e ingresar all&iacute; los datos del mismo. - Que sucede si no recibo la recarga? Es posible que la se hayan ingresado datos incorrectos al momento de generar la solicitud. En caso de que el inconveniente persista, comunicarse al 0810 555 2355.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-20">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-20" aria-expanded="false" aria-controls="collapse-20">
                20. &iquest;C&oacute;mo realizo un canje de Millas Aerol&iacute;neas Plus?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-20" class="collapse" aria-labelledby="group-20" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Los Puntos Macro Premia pueden canjearse por Millas Aerol&iacute;neas Plus, que pueden ser acreditadas a cualquier Socio Frecuente de Aerol&iacute;neas Plus. Para realizar el canje, se debe definir la cantidad de Puntos Macro Premia a canjear a partir de la relaci&oacute;n Puntos Macro Premia / Millas Aerol&iacute;neas Plus vigente, y completar el DNI o N&uacute;mero de Pasajero Frecuente a quien se desean acreditar las millas. Luego de realizar el canje, recibir&aacute; la confirmaci&oacute;n del mismo por mail, y en las pr&oacute;ximas 72hs., recibir&aacute; la confirmaci&oacute;n de acreditaci&oacute;n de las millas en la cuenta seleccionada, que ya estar&aacute;n disponibles para ser utilizadas. - &iquest;D&oacute;nde puedo ver las millas que solicit&eacute;? Para ver la totalidad de millas canjeadas ya impactadas es necesario ingresar al programa Millas Aerol&iacute;neas Plus, colocando all&iacute; los datos correspondientes. Esta informaci&oacute;n estar&aacute; disponible luego de 72 hs de realizado el canje. - &iquest;Puedo cargar millas a otra cuenta aerol&iacute;neas plus? Si, al momento de generar la solicitud se puede ingresar el DNI o n&uacute;mero de pasajero frecuente al cual queremos imputar las Millas canjeadas, este puede ser distinto del usuario Macro Premia.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-21">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-21" aria-expanded="false" aria-controls="collapse-21">
                21. &iquest;C&oacute;mo realizo un canje en Despegar.com?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-21" class="collapse" aria-labelledby="group-21" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Los Puntos Macro Premia pueden utilizarse para canjear Paquetes, Vuelos, Hoteles, Alquiler de autos o Actividades en Despegar.com. Adem&aacute;s, estos premios pueden canjearse con la modalidad Puntos + Pesos en cuotas seg&uacute;n la promoci&oacute;n vigente. - &iquest;C&oacute;mo ingreso a la plataforma? Para ingresar a la propuesta vigente de Despegar.com &ndash; Macro Premia es necesario ingresar a Banca Internet o App Macro y seleccionar la opci&oacute;n: Macro Premia Despegar. - &iquest;C&oacute;mo puedo abonar los productos de Despegar? Los productos de la propuesta Macro Premia &ndash; Despegar podr&aacute;n ser adquiridos mediante las modalidades de Full Puntos, Puntos + Pesos o Full Pesos. Abonando la parte de Pesos con las tarjetas de cr&eacute;dito de Banco Macro, en cuotas seg&uacute;n la promoci&oacute;n vigente.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-22">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-22" aria-expanded="false" aria-controls="collapse-22">
                22. &iquest;C&oacute;mo realizo un canje de Premios Instant&aacute;neos?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-22" class="collapse" aria-labelledby="group-22" >
            <div class="card-body">
              <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                <span style="font-size:12px">
                  <span style="background-color:white">
                    <span style="font-family:Tahoma,Geneva,sans-serif">
                      <span style="color:#333333">
                        Los Premios Instant&aacute;neos se pueden canjear y utilizar en el momento. Una vez realizado el canje, recibir&aacute; un mail de confirmaci&oacute;n dentro de las 24 horas de efectuada a solicitud, que presentar&aacute; en el comercio y podr&aacute; disfrutar de su premio. - &iquest;C&oacute;mo hago para obtener el premio instant&aacute;neo en el comercio? Es necesario presentar el Voucher que llega por email, luego de la confirmaci&oacute;n del canje. - &iquest;Qu&eacute; hago si no tengo sucursales del comercio cerca? Es necesario verificar los locales adheridos y sus direcciones antes de generar la solicitud, en la p&aacute;gina de Macro Premia. Estos premios no admiten devoluci&oacute;n ni cambio. - &iquest;D&oacute;nde puedo ver los comercios adheridos? La lista de comercios adheridos se encuentra detallada en la descripci&oacute;n de cada premio - &iquest;Cu&aacute;nto tiempo tengo para utilizar mi premio instant&aacute;neo? La vigencia de estos premios es hasta 30 d&iacute;as posteriores luego de la solicitud del canje.
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-23">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-23" aria-expanded="false" aria-controls="collapse-23">
                23. &iquest;C&oacute;mo realizo un canje de Entradas de Cine?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-23" class="collapse" aria-labelledby="group-23" >
            <div class="card-body">
              <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                <span style="font-size:12px">
                  <span style="background-color:white">
                    <span style="font-family:Tahoma,Geneva,sans-serif">
                      Los premios Entradas de Cine disponibles en Macro Premia corresponden a una entrada en sala tradicional, y no son v&aacute;lidos para cine 3D, eventos especiales ni Avant Premier. El voucher por entrada ser&aacute; recibido dentro de las 24hs de realizado el canje. El mismo deber&aacute; ser presentado en el cine.
                    </span>
                  </span>
                </span>
              </p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-24">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-24" aria-expanded="false" aria-controls="collapse-24">
                24. &iquest;C&oacute;mo realizo un canje de Gift Cards?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-24" class="collapse" aria-labelledby="group-24" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Los Puntos Macro Premia se pueden canjear por Gift Cards v&aacute;lidas en una gran variedad de comercios. Una vez realizado el canje, el cliente recibir&aacute; un mail de confirmaci&oacute;n dentro de las 24 horas de gestionada la solicitud para utilizar la Gift Card. La misma tendr&aacute; una validez de 180 d&iacute;as corridos desde la fecha de canje. Se deber&aacute; consultar previo al canje cu&aacute;les son los comercios adheridos para la Gift Card seleccionada en el link incorporado al pie de cada uno de los beneficios. Es necesario recordar que estas solicitudes no admiten cambios ni devoluci&oacute;n.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-25">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-25" aria-expanded="false" aria-controls="collapse-25">
                25. &iquest;C&oacute;mo realizo un canje de Experiencias?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-25" class="collapse" aria-labelledby="group-25" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">En Macro Premia hay disponible una gran variedad de experiencias gastron&oacute;micas: belleza, escapadas, gastronom&iacute;a y muchas opciones para disfrutar un gran momento. Una vez realizado el canje el cliente recibir&aacute; un mail de confirmaci&oacute;n dentro de las 24hs de realizada la solicitud.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-26">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-26" aria-expanded="false" aria-controls="collapse-26">
                26. &iquest;C&oacute;mo realizo un canje de Entradas para Espect&aacute;culos?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-26" class="collapse" aria-labelledby="group-26" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">Para disfrutar de los mejores espect&aacute;culos, el cliente puede canjear sus Puntos Macro Premia y disfrutar de los shows de los mejores artistas. El mail de confirmaci&oacute;n ser&aacute; recibido dentro de las 24hs de realizada la solicitud, en el mismo el cliente recibir&aacute; las indicaciones necesarias para retirar la entrada, dependiendo de la fecha y lugar del espect&aacute;culo. Antes de realizar el canje es necesario consultar las condiciones de retiro de las entradas detalladas en la descripci&oacute;n del premio.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-27">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-27" aria-expanded="false" aria-controls="collapse-27">
                27. &iquest;C&oacute;mo convertir puntos Macro Premia en Carga SUBE?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                </button>
            </h2>
        </div>

        <div id="collapse-27" class="collapse" aria-labelledby="group-27" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">El cliente podr&aacute; utilizar sus puntos para recargar su tarjeta SUBE. Es necesario ingresar a Macro Premia y seleccionar el men&uacute; SUBE. Una vez all&iacute; debe seleccionar el monto que desee cargar y colocar los datos del n&uacute;mero de su tarjeta SUBE. El cliente recibir&aacute; un mail con la confirmaci&oacute;n de la carga minutos despu&eacute;s de realizado el canje. Luego de recibir el mail de confirmaci&oacute;n, ser&aacute; necesario acreditar el saldo en una terminal Autom&aacute;tica o con el dispositivo de Conexi&oacute;n M&oacute;vil SUBE.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
        <div class="card-header p-0" id="group-28">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-28" aria-expanded="false" aria-controls="collapse-28">
                    28. &iquest;C&oacute;mo debo proceder en caso de tener inconvenientes con las solicitudes de premios virtuales?
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                    </button>
            </h2>
        </div>

        <div id="collapse-28" class="collapse" aria-labelledby="group-28" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:12px"><span style="background-color:white"><span style="font-family:Tahoma,Geneva,sans-serif"><span style="color:#333333">El cliente podr&aacute; contactarse con el Centro de Atenci&oacute;n Telef&oacute;nica al (0</span><span style="color:#333333">‐</span><span style="color:#333333">810</span><span style="color:#333333">‐</span><span style="color:#333333">555</span><span style="color:#333333">‐</span><span style="color:#333333">2355) o por Banca internet con clave y usuario dentro de los 10(diez) d&iacute;as siguientes de haber generado la solicitud.</span></span></span></span></p>
            </div>
        </div>
    </div>
    <!-- End Section -->
  </div>
  <!-- End Accodion FAQs -->


</div>

