//Angular
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
//Services
import { TechnisysService } from '../../../services/technisys.service';
import { LoaderService } from '../../../services/loader.service';
import { CheckoutService } from '../../../services/checkout.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { LocalStorage } from 'services/local_storage.service';
//Models
import { Customer } from "../../../models/models.model";
import { CheckoutCart } from '../../../models/models.model';
import { TechnisysUser } from "../../../models/models.model";
//Other
import { environment } from 'environments/environment';
import { TermsOfServicesComponent } from '../../dialogs/terms-of-services/terms-of-services.component';
import { MktService } from '../../new-core/core/services/mkt.service';

@Component({
    selector: '[app-login]',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.sass'],
})

export class LoginComponent implements OnInit  {
  checkoutCart: CheckoutCart;
  errors: any;
  facebook_app_id: any;
  loginForm: FormGroup;
  loginTechnisysForm: FormGroup;
  model =  new Customer();
  env = environment;
  @Output() isRecovery = new EventEmitter<boolean>();
  showRecovery: boolean = false;
  recoverySuccess: boolean = false;
  recovery_email = new FormControl('', [Validators.required, Validators.email]);
  passwordType: string = 'password';


  constructor(private authenticationService: AuthenticationService,
              private _mktService: MktService,
              private dialog: MatDialog,
              private router: Router,
              private technisysService: TechnisysService,
              private checkoutService: CheckoutService,
              private localStorage: LocalStorage,
              private loaderService: LoaderService) {}

  ngOnInit() {
    if(this.env.name != 'Rapiclub' ){
      this.buildForm();
      this.facebook_app_id = environment.facebook_app_id;
      if (this.authenticationService.isLoggedIn()) this.router.navigate(['/account/profile']);
      this._mktService.setMetaTags('Login | Registro', {name: "description", content: 'Login | Registro'});
    } else {
      this.buildTechnisysForm();
      if (this.authenticationService.isLoggedIn()) this.router.navigate(['/home']);
      this._mktService.setMetaTags('Login', {name: "description", content: 'Login'});
    }
    this.checkoutCart = this.checkoutService.getCart();
  }

  buildForm() {
    this.loginForm = new FormGroup({
      'email': new FormControl(null, [
        Validators.required,
        Validators.email
      ]),
      'password': new FormControl(null, [
        Validators.required
      ])
    });
  }

  buildTechnisysForm() {
    this.loginTechnisysForm = new FormGroup({
      'username': new FormControl(null, [
        Validators.required,
      ]),
      'second_factor': new FormControl(null, [
        Validators.required,
        Validators.minLength(4)
      ]),
      'password': new FormControl(null, [
        Validators.required
      ])
    });
  }

  submit() {
    this.loaderService.show();
    if (this.loginForm.valid) {
      this.model = this.loginForm.value;
      let purchage_id = this.checkoutCart ? this.checkoutCart.purchase_id : null;
      this.authenticationService.login(this.model, purchage_id).subscribe(
        (response: any) => {
          this.loaderService.hide();
          if (response.success) {
            if (this.isCheckout()) {
              this.authenticationService.broadcastLogin();
            } else {

              this.redirectUser()
              this.authenticationService.broadcastLogin();
            }
          } else {
            this.errors = "Hubo un error, intente nuevamente."
          }
        },
        (error) => {
          this.errors = error.error.error.text;
          this.loaderService.hide();
        }
      );
    } else {
      this.loaderService.hide();
    }
  }

  redirectUser() {

    // Check if user has been trying to add or purchase a product
    if (localStorage.getItem('previuosUrlBeforeLogin')) {
      const previuosUrlBeforeLogin = localStorage.getItem('previuosUrlBeforeLogin');
      localStorage.removeItem('previuosUrlBeforeLogin');
      this.router.navigate([`${previuosUrlBeforeLogin}`]);
      this.authenticationService.broadcastLogin();
    } else if(this.localStorage.get('lastProductUrl')) {
      // Get Product URL and redirect
      const productUrl = this.localStorage.get('lastProductUrl')
      this.localStorage.remove('lastProductUrl')
      this.router.navigate([`/products/${productUrl}`]);
      this.authenticationService.broadcastLogin();
    } else {
      this.router.navigate(['/account/profile']);
    }

  }

  submitTechnisys() {
    this.loaderService.show();
    if (this.loginTechnisysForm.valid){
      let technisysFormModel = this.loginTechnisysForm.value;
      let purchage_id = this.checkoutCart ? this.checkoutCart.purchase_id : null;
      this.technisysService.login(technisysFormModel, purchage_id).subscribe(
        (response: any) => {
          this.loaderService.hide();
          if (response.success) {
            if (this.isCheckout()) {
              this.authenticationService.broadcastLogin();
            } else {
              this.loaderService.hide();
              this.router.navigate(['/']); //this.router.navigate(['/account/profile']); cambiado hasta crear usuarios
            }
          } else {
            this.loaderService.hide();
            this.errors = response.description
          }
        },
        (error) => {
          this.errors = error.error.error.text;
          this.loaderService.hide();
        }
      );
    }
  }

  clearErrors() {
    if (this.errors) this.errors = null;
  }

  isCheckout() {
    return this.router.url === '/checkout';
  }

  termsPopup() {
    this.dialog.open(TermsOfServicesComponent, {
      panelClass: 'termsOfService-dialog-container',
      width: '700px',
      height: '650px'
    });
  }

  toggleRecovery() {
    this.showRecovery = !this.showRecovery;
    this.isRecovery.emit(this.showRecovery);

  }

  recoveryPassword() {

      this.loaderService.show();
      if (this.recovery_email.valid) {
        this.authenticationService.recoveryPassword(this.recovery_email.value).subscribe(
          (response: any) => {
            this.loaderService.hide();
            console.log(response);

            this.recoverySuccess = true;
            setTimeout(() => {
              this.clearErrors();
              this.router.navigate(['/']);
            }, 5000);

          },
          (error) => {
            this.errors = "Hubo un error, intente nuevamente más tarde.";
            this.loaderService.hide();
            setTimeout(() => {
              this.clearErrors();
              this.router.navigate(['/']);
            }, 5000);
          }
        );
      } else {
        this.loaderService.hide();
      }

  }

  showPassword() {

    if (this.passwordType === 'password') {

      this.passwordType = 'text';

      setTimeout(() => {

        this.passwordType = 'password';

      }, 3000);

    } else {

      this.passwordType = 'password';

    }

  }

}
function show(): string {
  throw new Error('Function not implemented.');
}

