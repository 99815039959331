import {Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import {Menu} from '../../../../models/models.model';
import {TijeComponent} from "../../../dialogs/tije/tije.component";
import { UrlService } from "../../../../services/url.service";
import {MatDialog} from "@angular/material/dialog";
import { environment } from 'environments/environment';

@Component({
  selector: 'app-button-link-mobile',
  templateUrl: './button-link-mobile.component.html',
  styleUrls: ['./button-link-mobile.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonLinkMobileComponent implements OnInit {
  @Input('menu') menu: Menu;
  @Input('position') position: number;

  @Output() closed = new EventEmitter();
  constructor(private dialog: MatDialog,
              private urlService: UrlService) {}

  ngOnInit() {
  }

  hasChilds(item) {
    return item.childs.length > 0;
  }

  navigate(url) {
    if (this.menu.isTravel()) {
      if (environment.name === "Avenida") this.dialog.open(TijeComponent).updateSize('790px', '510px');
    } else {
      this.urlService.navigate(url);
    }
    this.closeSide();
  }

  closeSide() {
    this.closed.emit(true);
  }

  changeIcon(item) {
    const classes = document.getElementById(item).className;
    if (classes.includes('down')) {
      document.getElementById(item).classList.remove('fe-chevron-down');
      document.getElementById(item).classList.add('fe-chevron-up');
    } else {
      document.getElementById(item).classList.remove('fe-chevron-up');
      document.getElementById(item).classList.add('fe-chevron-down');
    }

  }

}
