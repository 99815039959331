//Angular
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InsuranceService } from '../../../services/insurance.service';
import { Insurance } from '../../../interfaces/insurance.interface';
import { PriceService } from '../../../services/price.service';
import { MatStepper } from '@angular/material/stepper';
import { Router, ActivatedRoute, Params } from "@angular/router";


@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.sass']
})
export class InsuranceComponent implements OnInit {
  insuranceForm: FormGroup;
  insurance: Insurance;
  processData: boolean;
  success: boolean;
  insuranceCost: number = 0;
  item: string;
  order_id: string;
  message: string;
  error: string;
  token: string;
  docTypes = ["DNI", "LC", "LE"];
  genders = ["Femenino", "Masculino", "Otro"]
  civil_status = ["Soltera/o", "Casada/o", "Viuda/o", "Divorciada/o"]
  invalidForm = false;

  menorEdad: boolean = false;

  provincias = [
    { id: '1' , value: 'Capital Federal'},
    { id: '2' , value: 'Buenos Aires'},
    { id: '4' , value: 'Catamarca'},
    { id: '5' , value: 'Chaco'},
    { id: '6' , value: 'Chubut'},
    { id: '7' , value: 'Córdoba'},
    { id: '8' , value: 'Corrientes'},
    { id: '9' , value: 'Entre Ríos'},
    { id: '10', value: 'Formosa'},
    { id: '11', value: 'Jujuy'},
    { id: '12', value: 'La Pampa'},
    { id: '13', value: 'La Rioja'},
    { id: '14', value: 'Mendoza'},
    { id: '15', value: 'Misiones'},
    { id: '16', value: 'Neuquén'},
    { id: '17', value: 'Río Negro'},
    { id: '18', value: 'Salta'},
    { id: '19', value: 'San Juan'},
    { id: '20', value: 'San Luis'},
    { id: '21', value: 'Santa Cruz'},
    { id: '22', value: 'Santa Fe'},
    { id: '23', value: 'Santiago del Estero'},
    { id: '24', value: 'Tierra del Fuego'},
    { id: '25', value: 'Tucumán'},
  ]
  nacionalidades = [
    'ARGENTINA',
    'CHILENA',
    'URUGUAYA',
    'VENEZOLANA',
    'BOLIVIANA',
    'BRASILEÑA',
    'COLOMBIANA',
    'PERUANA',
    'ALBANESA',
    'ALEMANA',
    'ANDORRANA',
    'ARABE SAUDITA',
    'ARGELINA',
    'ARMENIA',
    'AUSTRALIANA',
    'AUSTRIACA',
    'BARBADENSE',
    'BELGA',
    'BIELORRUSA/BELARUSA',
    'BRITANICA',
    'BULGARA',
    'CAMERUNESA',
    'CANADIENSE',
    'CHECA',
    'CHINA',
    'CHIPRIOTA',
    'CONGOLEÑA',
    'COSTARRICENSE',
    'CROATA',
    'CUBANA',
    'DANESA/DINAMARQUESA',
    'DOMINICANA',
    'ECUATORIANA',
    'EGIPCIA',
    'ESLOVACA',
    'ESLOVENA',
    'ESPAÑOLA',
    'ESTADOUNIDENSE',
    'ESTONIA',
    'FILIPINA',
    'FINLANDESA/FINESA',
    'FRANCESA',
    'GHANESA',
    'GRIEGA',
    'GUATEMALTECA',
    'GUYANESA',
    'HAITIANA',
    'HONDUREÑA',
    'HUNGARA',
    'INDIA',
    'INDONESIA',
    'IRANI',
    'IRAQUI',
    'IRLANDESA',
    'ISLANDESA',
    'ISRAELI',
    'ITALIANA',
    'JAMAIQUINA',
    'JAPONESA/NIPONA',
    'JORDANA',
    'KENIANA',
    'KUWAITI',
    'LIBANESA',
    'LIBERIANA',
    'LITUANA',
    'LUXEMBURGUESA',
    'MALAYA/MALASIA',
    'MARROQUI',
    'MEXICANA',
    'MONGOLA',
    'NEOCELANDESA',
    'NICARAGÜENSE',
    'NIGERIANA',
    'NORCOREANA',
    'NORUEGA',
    'PAKISTANI/PAQUISTANI',
    'PALESTINA',
    'PANAMEÑA',
    'PARAGUAYA',
    'POLACA',
    'PORTUGUESA',
    'RUMANA',
    'RUSA',
    'SALVADOREÑA',
    'SAMOANA',
    'SINGAPURENSE',
    'SIRIA',
    'SUDAFRICANA',
    'SUECA',
    'SUIZA',
    'SURCOREANA',
    'TAILANDESA',
    'TAIWANESA',
    'TUNECINA',
    'TURCA',
    'UCRANIANA',
    'VIETNAMITA',
    'YUGOSLAVA',
    'OTRAS'
  ]

  constructor(
    private insuranceService: InsuranceService,
    private router: Router,
    private route: ActivatedRoute,) { }

  ngOnInit() {
    this.token = this.route.snapshot.params['token'];
    this.buildForm();
    this.validateToken();

  }

  buildForm() {
    this.insuranceForm = new FormGroup({
      'name': new FormControl(null, [
        Validators.required,
      ]),
      'email': new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)
      ]),
      'phone': new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[0-9]{6,12}$/i),
        Validators.minLength(6),
      ]),
      'birthday': new FormControl(null, [
        Validators.required,
      ]),
      'birth_location': new FormControl('', [
        Validators.required,
      ]),
      'nationality': new FormControl('ARGENTINA', [
        Validators.required,
      ]),
      'gender': new FormControl('Femenino', [
        Validators.required,
      ]),
      'civil_status': new FormControl('Soltera/o', [
        Validators.required,
      ]),
      'profession': new FormControl(null, [
        Validators.required,
      ]),
      'doc_type': new FormControl('DNI', [
        Validators.required,
      ]),
      'doc_number': new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[0-9]{4,9}$/i),
        Validators.minLength(4),
        Validators.maxLength(9),
      ]),
      'address': new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
      ]),
      'street_number': new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/i),
      ]),
      'floor': new FormControl(null, [
      ]),
      'depth': new FormControl(null, [
      ]),
      'country': new FormControl('AR', [
      Validators.required
      ]),
      'state': new FormControl('Capital Federal', [
        Validators.required,
        Validators.minLength(3),
      ]),
      'city': new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
      ]),
      'postal_code': new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
      ]),
      'political_exposure': new FormControl(false, []),
      'option': new FormControl('card_number', []),
      'card_number': new FormControl(null, [
        Validators.minLength(14),
        Validators.maxLength(18)]
      ),
      'cbu': new FormControl(null, [
        Validators.minLength(22),
        Validators.maxLength(22)
        ]
      ),
      'token': new FormControl(null, []),
    });

    this.insuranceForm.valueChanges.subscribe((val) => {
      this.invalidForm = !this.insuranceForm.valid;
    });

    this.insuranceForm.get('option').valueChanges.subscribe((value) =>{
      if(value === 'card_number') {
        // selecciono tarjeta
        this.insuranceForm.get('cbu').reset();

        // Agregar validaciones dinamicamente

        this.insuranceForm.get('card_number').setValidators([Validators.required, Validators.minLength(14), Validators.maxLength(18)]);
        this.insuranceForm.get('cbu').setValidators([]);

        this.insuranceForm.get('card_number').updateValueAndValidity()
        this.insuranceForm.get('cbu').updateValueAndValidity()

      } else {
        // selecciono cbu
        this.insuranceForm.get('card_number').reset();

        this.insuranceForm.get('cbu').setValidators([Validators.required, Validators.minLength(22), Validators.maxLength(22)]);
        this.insuranceForm.get('card_number').setValidators([]);

        this.insuranceForm.get('cbu').updateValueAndValidity()
        this.insuranceForm.get('card_number').updateValueAndValidity()
      }

    });

    this.insuranceForm.get('birthday').valueChanges.subscribe((value)=>{

      if(this.insuranceForm.get('birthday').valid){
        this.calculatBirthdate(value);
      }

    });
  }

  calculatBirthdate(value: any){

    let birthDate = new Date(value);

    let diference = Date.now() - birthDate.getTime();
    let ageDate = new Date(diference);

    let calculatedAge=   Math.abs(ageDate.getUTCFullYear() - 1970);

    if(calculatedAge < 18){

      this.menorEdad = true;
    }else{

      this.menorEdad = false;
    }

  }

  patchForm(preloaded) {
    if (preloaded != null) {
      this.insuranceForm.patchValue({
        'address': preloaded.customer.address,
        'email': preloaded.customer.email,
        'name': preloaded.customer.first_name + " " + preloaded.customer.last_name,
        'token': this.token
      });
    }
  }

  async validateToken() {
    await this.insuranceService.validateToken(this.token).subscribe(
      (response) => {
      if (response) {
        console.log(response)
        if(response.message){
          this.message = response.message;
        } else {
          this.insuranceCost = response.insurance_data.ARS;
          this.item = response.insurance_data.item_title;
          this.order_id = response.insurance_data.order_id;
          this.patchForm(response);
        }
      } else {
        this.router.navigate(['/']);
      }
    },(error) => {
      this.router.navigate(['/']);
    });
  }

  async requestInsurace() {
    this.processData = true;
    let params = this.insuranceForm.value
    await this.insuranceService.acredit(this.insuranceForm.value).subscribe(
      (response) => {
        if (response) {
          this.processData = false;
          this.success = true;
        } else {
          this.error = 'No fue posible completar la transacción';
          this.processData = false;
          this.success = false;
        }
      }, (error) => {
        this.error = 'No fue posible completar la transacción';
        this.processData = false;
        this.success = false;
      }
    )
  }

  submitInsuranceForm(stepper: MatStepper) {
    if(this.insuranceForm.valid && !this.menorEdad) {
        stepper.next()
        this.requestInsurace();
      } else {
        this.invalidForm = true;
      }
  }

  floatParse(number: number){
    return PriceService.humanize(number);
  }
}
