import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SubeService } from 'services/sube.service';

@Component({
  selector: 'app-new-card',
  templateUrl: './new-card.component.html',
  styleUrls: ['./new-card.component.sass']
})
export class NewCardComponent implements OnInit {
  newCard: FormGroup;
  isLoaded = false;
  errors: string;
  validCard = true;
  selectedCard: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private subeService: SubeService,
              private _formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<NewCardComponent>) { }

  ngOnInit() {
    this.isLoaded = true;
    this.formsGenerator();
  }

  formsGenerator() {
    this.newCard = this._formBuilder.group({
      'alias': new FormControl(null, [
        Validators.required
      ]),
      'number': new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/i),
        Validators.min(16)
      ]),
      'confirm': new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/i),
        Validators.min(16)
      ]),
    });

    this.newCard.valueChanges.subscribe( () => {
      this.errors = '';
      if (this.newCard.get('number').valid) {
        this.validateCard();
      }
    });

  }

  validateCard() {
    const subeCard = this.newCard.getRawValue().number;

    return this.subeService.isValidCard(subeCard).subscribe(
      (response) => {
        if (response.success === true) {
          this.validCard = true;
        } else {
          this.validCard = false;
        }
      }, (error) => {
        console.log(error);
      });
    
  }

  validator() {
    if (this.newCard.getRawValue().number !== this.newCard.getRawValue().confirm) {
      this.errors = "El número y la confirmación no coinciden"
      return false;
    } 
    return true;
  }
  
  addNewCard() {

    if (this.newCard.valid) {
      if (this.validator()) {
        this.subeService.createCard(this.newCard.value).subscribe(
          (response) => {
            this.selectedCard = this.newCard.getRawValue().number;
            this.closeDialog();
          },
          (error) => {
            this.errors = 'Ha ocurrido un error, intenta nuevamente'
          }
        );
      }  
    } else {
      this.errors = "Complete todos los campos";
    }
     
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
