import { AuthenticationService } from '../services/authentication.service';
import { Injectable } from '@angular/core';
import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import { environment } from 'environments/environment';

@Injectable()
export class AuthGuardService implements CanActivate {

  // you would usually put this in it's own service and not access it directly!
  // this is just for the sake of the demo.
  isLoggedIn: boolean = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!environment.login) {
      this.router.navigate(['/']);
      return false;
    }

    this.isLoggedIn = this.authenticationService.isLoggedIn();
    if (this.isLoggedIn || state.url === '/login') {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

}
