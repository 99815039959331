import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Customer } from "../models/models.model";
import { map } from 'rxjs/operators';
import { LocalStorage } from './local_storage.service';
import { CustomerService } from './customer.service';
import { CheckoutService } from './checkout.service';
import { UrlService } from './url.service';
import { environment } from '../environments/environment';
import { PointsService } from './points.service';
import { UserIdleService } from './user-inactivity.service';

@Injectable()
export class AuthenticationService {
  authentication = new Subject<boolean>();

  constructor(private http: HttpService,
              private localstorage: LocalStorage,
              private pointsService: PointsService,
              private customerService: CustomerService,
              private checkoutService: CheckoutService,
              private urlService: UrlService,
              private router: Router,
              private userIdleService:UserIdleService) {}

  login(user: Customer, purchase_id: any) {
    return this.http.basePost('sessions',
      JSON.stringify({
        email: user.email,
        password: user.password,
        purchase_id: purchase_id })
    ).pipe(
      map(
        (response) => {
          const userResponse: any  = response;
          if ( userResponse && userResponse.token ) {
            this.localstorage.set('auth_token', userResponse.token);
            this.http.addHeader('auth-token', userResponse.token);
            this.updateDataCustomer();
          }
          return userResponse;
        }
      )
    )
  }

  updateDataCustomer(){

    // ---------- after login, updating data of Customer ----------//
    // ---------- Luego del login, guardamos los datos del Customer ----------//
    this.customerService.init().subscribe(
      (response: Customer) => {
        this.customerService.setCustomer(response);
      },
      (error) => {
        console.log(error);
      }
    );

  }

  broadcastLogin() {
    this.authentication.next(true);
  }

  isLoggedIn() {
    if (this.localstorage.get('auth_token')) {
      this.http.addHeader('auth-token', this.localstorage.get('auth_token'));
      return true;
    }
    return false;
  }

  token() {
    return this.localstorage.get('auth_token');
  }

  logout() {
    this.http.get('users/logout').subscribe(
      (response) => {
        this.localstorage.remove('auth_token');
        this.http.removeHeader('auth-token');
        this.checkoutService.destroyCart();
        this.customerService.destroyCustomer();
        this.pointsService.destroyPoints();
        this.authentication.next(false);
        this.userIdleService.stopTimers();
        if (environment.name !== 'Macro') {
          setTimeout(() => { this.router.navigate(['/']) }, 300);
        }
      }
    );
  }

  async checkedUserExist(
    user: any){

      return this.http.post('users/validate_attributes', {user}).toPromise()
  }

  register(user: Customer) {

    const newUser = (environment.name === 'BancoNacion')
                  ? {user}
                  : JSON.stringify( {
                    user: {
                    email: user.email,
                    password: user.password,
                    password_confirmation: user.password_confirmation
                    }
                  })

    return this.http.post('users',newUser);
  }

  omniAuth(user: any, provider: string) {
    return this.http.basePost('users/omniauth',
      JSON.stringify({
        provider: provider,
        token: user.accessToken,
        user: user })
    ).pipe(
      map(
        (response: any) => {
          if ( response && response.token ) {
            this.http.addHeader('auth-token', response.token);
            this.localstorage.set('auth_token', response.token);
            this.authentication.next(true);
          }
        }
      )
    )
  }

  isExternalLogin() {
    return environment.external_login;
  }

  recoveryPassword(email: string) {
    return this.http.basePost('password_recovery',
      JSON.stringify({ email })
    )
  }
}
