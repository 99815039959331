import { QuicklinkStrategy } from 'ngx-quicklink';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { TermsComponent } from './pages/terms/terms.component';
import { AgentComponent } from './pages/agent/agent.component';
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes  } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ErrorPageComponent } from "./error-page/error-page.component";
import { TrackingComponent } from './tracking/tracking.component';
import { TurismoComponent } from "./pages/turismo/turismo.component";
import { LegacyComponent } from "./legacy/legacy.component";
import { SitemapComponent } from "./sitemap/sitemap.component";
import { CustomerComponent } from "./customer/customer.component";
import { LogoutComponent } from "./customer/logout/logout.component";
import { AuthGuardService } from './auth-guard.service';
import { ExternalComponent } from "./customer/external/external.component";
import { DespegarComponent } from './pages/despegar/despegar.component';
import { AerolineasArgentinasComponent } from './pages/aerolineas-argentinas/aerolineas-argentinas.component';
import { InsuranceComponent } from './pages/insurance/insurance.component'
import { SubeComponent } from './pages/sube/sube.component';
import { CelularesComponent } from './pages/celulares/celulares.component';
import { SessionExpiredComponent } from './pages/session-expired/session-expired.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { environment } from 'environments/environment';
import { BnaXmlComponent } from './bna-xml/bna-xml.component';
import { AerolineasCanActivateGuardService } from './guards/aerolineas-argentina.can-activate.guard';

var env = environment.name;

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'selecta', component: HomeComponent, canActivate: [AerolineasCanActivateGuardService] },
  { path: 'macro_token/:token', component: ExternalComponent, canActivate: [AerolineasCanActivateGuardService] },
  { path: 'mimoto/:token', component: ExternalComponent },
  { path: 'especiales/:token', component: ExternalComponent },
  { path: 'seguros/:token', component: InsuranceComponent },
  { path: 'despegar', component: DespegarComponent, canActivate: [AerolineasCanActivateGuardService] },
  { path: 'aerolineas-plus', component: AerolineasArgentinasComponent, canActivate: [AerolineasCanActivateGuardService] },
  { path: 'carga-sube', component: SubeComponent, canActivate: [AerolineasCanActivateGuardService] },
  { path: 'recarga-celulares', component: CelularesComponent, canActivate: [AerolineasCanActivateGuardService] },
  { path: 'catalog', loadChildren: () => import('./catalog/catalog.module').then(m => m.CatalogModule) },
  // { path: 'checkout', loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule) },
  {
    path: 'checkout', loadChildren: () => env === 'BancoNacion'
      ? import('./new-core/checkout/checkout.module').then(m => m.CheckoutNewModule)
      : import('./checkout/checkout.module').then(m => m.CheckoutModule)
  },
  { path: 'products/:id', loadChildren: () => import('./products/products.module').then(m => m.ProductsModule) },
  { path: 'brands/:id', loadChildren: () => import('./brands/brands.module').then(m => m.BrandsModule) },
  { path: 'tracking', component: TrackingComponent },
  { path: 'tracking/:tracking_number', component: TrackingComponent },
  { path: 'ar/:legacy', component: LegacyComponent },
  { path: 'ar/:legacy/:old', component: LegacyComponent },
  { path: 'turismo', component: TurismoComponent },
  { path: 'not-found', component: ErrorPageComponent },
  { path: 'sitemap', component: SitemapComponent },
  { path: 'login', component: CustomerComponent, canActivate: [AuthGuardService] },
  { path: 'register', loadChildren: () => import('./new-core/register/register.module').then(m => m.RegisterModule)},
  { path: 'logout', component: LogoutComponent },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'session-expired', component: SessionExpiredComponent },
  { path: 'mantenimiento', component: MaintenanceComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'agent', component: AgentComponent },
  { path: 'consulta/feed/emblue', component: BnaXmlComponent },
  { path: ':id', loadChildren: () => import('./pages/landings/landings.module').then(m => m.LandingsModule) },
  { path: '**', redirectTo: '/not-found'}
];

export const appRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    initialNavigation: 'enabled'
});
