import { Injectable, Injector } from '@angular/core';
import {HttpService} from './http.service';
import Dictionary from 'typescript-collections/dist/lib/Dictionary';
import * as lodash from 'lodash-es';
// import {RelationService} from './relations.service';
import {Observable, Subject} from 'rxjs';
import {RelationModel} from '../models/models.model';
import {Params} from "@angular/router";
import { map } from 'rxjs/operators';
import {Category} from '../models/models.model';


@Injectable()

export class RelationService {
  private categoriesService: CategoriesService;
  constructor(
    private injector: Injector
    ) {
      setTimeout(() => this.importServices());
  }

  // gimme an object an i give you a model instance
  // laik parse({product data}, Product)
  public parse<T extends RelationModel>(input: {}, type: { new(): T }): T {
    if (input === null) {
      return null;
    }

    const model = new type();
    this.addAttributes(model, input);
    this.addRelations(model, input);
    model.afterParse(); // model specific entanglement
    return model;
  }

  // Adds the basic attributes to the model, for example
  // id, title, slug
  private addAttributes(model: RelationModel, input: {}) {
    for (const attr of model.attributes()) {
      model[attr] = input[attr];
    }
  }

  // Adds the relations to the model, if it's a sub-model
  // it parses it with this
  // Remember, a model can have multiple hierarchy models
  private addRelations(model: RelationModel, input: {}) {
    // belongs to relation
    for (const relation of model.belongsTo()) {
      const name = relation.name;
      const modelClass = relation.class;
      const value = input[name];

      if (input[name] === undefined) {
        model[name] = {};
      } else {
        model[name] = this.getRelationFrom(modelClass, value);
      }
    }

    // has many
    for (const relation of model.hasMany()) {
      const name = relation.name;
      const modelClass = relation.class;
      const collection = [];

      if (input[name] !== undefined) {
        for (const value of input[name]) {
          collection.push(this.getRelationFrom(modelClass, value));
        }
      }

      model[name] = collection;
    }
  }

  private getRelationFrom(modelClass: {new(): RelationModel}, value: any): RelationModel {
    if (typeof value === 'number') {
      // its an ID!
      // let's get it from the collection of the service
      return this.getServiceFor(modelClass).getById(value);
    } else {
      // its an Object!
      // lets make a model instance
      return this.parse(value, modelClass);
    }
  }

  private getServiceFor(model: any) {
    switch (model) {
      case Category:
        return this.categoriesService;
    }
  }

  // we must import it async because of circular DI
  private importServices() {
    this.categoriesService = this.injector.get(CategoriesService);
  }
}
// This is a superclass for a collectionable rest resource,
// needs Http and Relations Service to be passed on super
export class ModelService<T extends RelationModel> {
  private collection = new Dictionary<number, T>();
  protected instances: T[] = [];
  updated = new Subject<boolean>();

  constructor(private http: HttpService,
              private relationsService: RelationService,
              private endpoint: string,
              private model: { new(): T}
              ) {
  }

  index(): Observable<T[]> {
    return this.http.get(this.endpoint).pipe(
      map(
        (response) => {
          const all = [];
          lodash.each(response, (item) => {
            const inst = this.relationsService.parse(item, this.model);
            if (item.id !== undefined) { // Este proceso se debe mejorar.
              this.collection.setValue(item.id, inst);
            } else if (item.title !== undefined) { // Esto es para menu... no borrar
              this.collection.setValue(item.title, inst);
            } else if (item.number !== undefined) { // Esto es para bines... no borrar
              this.collection.setValue(item.number, inst);
            }
          });
          return this.collection.values();
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  getById(number): T {
    return this.collection.getValue(number);
  }

  hasId(number): boolean {
    return this.collection.containsKey(number);
  }

  find(id: number | string, params?: Params): Observable<T> {
    let finalEndpoint = '';

    if (id === undefined) {
      finalEndpoint = this.endpoint;
    } else {
      finalEndpoint = `${this.endpoint}/${id}`;
    }

    return this.http.get(finalEndpoint, params).pipe(
      map(
        (response) => {
            const inst = this.relationsService.parse(response, this.model);
            this.collection.setValue(response.id, inst);
            return inst;
        },
        (error) => {}
      )
    );
  }

  update(id: number, params: any) {
    const final = `${this.endpoint}/${id}`;
    return this.http.patch(final, params).pipe(
      map(
      (response) => {
          this.updated.next(true);
          return response;
      }
    ));
  }

  create(params: any){
    return this.http.post(this.endpoint, params).pipe(
      map(
      (response) => {
        this.updated.next(true);
        return response;
      })
    );
  }

  delete(id: number, params: any) {
    const final = `${this.endpoint}/${id}`;
    return this.http.delete(final, params).pipe(
      map(
      (response) => {
        this.updated.next(true);
        return response;
      })
    );
  }
  
  broadcastUpdate() {
    this.updated.next(true);
  }

}



@Injectable()

export class CategoriesService extends ModelService<Category> {
  
  constructor(
    private injRelations: RelationService,
    private injHttp: HttpService,
    private injector: Injector,
    ) {
      super(injHttp, injRelations, 'categories', Category);
  }

  index(): Observable<Category[]> {
    
    return super.index().pipe(
      map(
        res => {
        
          this.associateParentsAndChilds(res);
          return res;
        },
        error => {
          console.error(error);
        })
    );
  }

  private associateParentsAndChilds(categories: Category[]): void {
    
    lodash.forEach(categories, (cat) => {
      
      if (this.hasId(cat.parent)) {
        
        const parent = this.getById(cat.parent);
        
        cat.parent = parent;
        
        parent.childs.push(cat);
      }
    });
  }
}

