<div class="content">
    <!-- Accodion FAQs -->
    <p style="text-align:center">
        <span [ngStyle]="env.name !== 'Macro' ? 'font-family:Tahoma,Geneva,sans-serif' : ''">
            <span style="font-size:24px">
                <strong>T&eacute;rminos y Condiciones </strong>
            </span>
        </span>
    </p>

    <p>&nbsp;</p>

    <div *ngIf="option === 0" class="accordion">
    </div>

    <div *ngIf="option === 1" class="accordion">
        <!-- Section -->
        <div class="card">
            <div class="card-header p-0" id="group-0">
                <h2 class="mb-0">
                    <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-0" aria-expanded="false" aria-controls="collapse-0">
                        PRESENTACI&Oacute;N
                    </button>
                </h2>
            </div>

            <div class="collapse" id="collapse-0">
                <div class="card-body sub-title-macro">
                    <p style="text-align:justify">
                        <span>
                            <span style="font-size:14px">
                                <strong>&Uacute;ltima Actualizaci&oacute;n:&nbsp;04/04/2022</strong>
                            </span>
                        </span>
                    </p>

                    <p style="text-align:justify">
                        <span style="font-size:12px">
                            <span>
                                El Programa Macro Premia<strong> </strong>(&ldquo;Macro Premia&rdquo;) es una propuesta exclusiva para los clientes (los &ldquo;Cliente/s&rdquo;) &nbsp;de Banco Macro (&ldquo;Banco Macro&rdquo;) por el que podr&aacute;n adquirir una amplia gama de productos, servicios y/o beneficios (los &ldquo;Beneficios&rdquo;), mediante el canje de puntos Macro Premia (&ldquo;Puntos Macro Premia&rdquo;), canje de Puntos Macro Premia m&aacute;s pesos, o bajo la modalidad solo Pesos (&ldquo;Full Pesos&rdquo;).
                            </span>
                        </span>
                    </p>

                    <p style="text-align:justify">
                        <span style="font-size:12px">
                            <span>
                                La participaci&oacute;n en el Programa implica la aceptaci&oacute;n de los presentes T&eacute;rminos y Condiciones.
                            </span>
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <!-- End Section -->

        <!-- Section -->

        <div class="card">
            <div class="card-header p-0" id="group-1">
                <h2 class="mb-0">
                    <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                        1. CONDICIONES DE PARTICIPACI&Oacute;N
                        <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                    </button>
                </h2>
            </div>

            <div class="collapse" id="collapse-1">
                <div class="card-body sub-title-macro">
                    <p style="text-align:justify">
                        <span style="font-size:12px">
                            <span>
                                <strong>1.1</strong> Para participar en Macro Premia será necesario: i) ser Cliente de la cartera consumo de Banco
                                Macro, ii) contar con una Caja de Ahorro en Banco Macro y una tarjeta de Débito Visa emitida por
                                Banco Macro activas; y iii) poseer al menos una Tarjeta de Crédito VISA, AMERICAN EXPRESS y/o
                                MASTERCARD emitida por Banco Macro (la “Tarjeta de Crédito”) que se encuentre en estado activo
                                y en situación normal de pago, así como también no registrar mora o incumplimiento en el pago de
                                alguno de los productos o servicios contratados con Banco Macro, en caso de registrar alguna de
                                las condiciones antes mencionadas, perderá el derecho de realizar canjes o adquisiciones de
                                Beneficios hasta tanto regularice tal situación, sin que ello importe la pérdida de puntos. La
                                regularización de la situación no implicará que renazca el derecho de realizar el canje o adquisición
                                del Beneficio que no pudo ser realizado por estar en tal condición, por lo cual sólo podrá canjear o
                                adquirir Beneficios a partir de la regularización. El cierre o baja de la totalidad de productos
                                mediante los cuales el Cliente acumule Puntos Macro Premia o dejar de ser Cliente del Banco,
                                significará la cancelación de la participación en Macro Premia provocando la pérdida de todos los
                                Puntos Macro Premia acumulados así como aquellos pendientes de acreditar.
                            </span>
                        </span>
                    </p>

                    <p style="text-align:justify">
                        <span style="font-size:12px">
                            <span>
                              Las Tarjetas de Crédito adheridas al Programa Aerolíneas Plus no acumularán Puntos Macro Premia,
                              excepto que se habilite la acumulación en alguna promoción en particular, situación que será
                              informada a través de Banca Internet y/o en la web del programa, no obstante si estarán habilitados
                              para adquirir Beneficios mediante la modalidad Full Pesos.
                            </span>
                        </span>
                    </p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify">
                        <span style="font-size:12px">
                            <span>
                                <strong>1.2</strong> Se encuentran excluidas de Macro Premia las Tarjetas de Crédito de Banca Empresas, Agro,
                                Tarjetas de Débito, Tarjetas de Crédito que se encuentren inhabilitadas o dadas de baja al momento
                                de la solicitud de adhesión a Macro Premia, durante la vigencia del mismo, al momento de la entrega
                                de Beneficios o que registren atraso o incumplimiento en el pago de las liquidaciones mensuales.
                                Asimismo, se encontrarán excluidos los Clientes que se encuentren en situación de incumplimiento
                                en el pago de alguno de los productos o servicios contratados con Banco Macro.
                            </span>
                        </span>
                    </p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify">
                        <span style="font-size:12px">
                            <span>
                                <strong>1.3</strong> Para acceder al Programa Macro Premia, se deberá ingresar a Banca Internet en la opción Macro Premia /Canje de puntos.
                            </span>
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <!-- End Section -->
        <!-- Section -->

        <div class="card">
            <div class="card-header p-0" id="group-2">
                <h2 class="mb-0">
                    <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                        2. ACUMULACI&Oacute;N DE PUNTOS Y FORMA DE C&Aacute;LCULO
                        <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                    </button>
                </h2>
            </div>

            <div class="collapse" id="collapse-2">
                <div class="card-body sub-title-macro">
                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>2.1</strong> Acumulan Puntos Macro Premia los consumos realizados en un pago y/o en cuotas a medida
                      que éstas se acrediten y los débitos automáticos, realizados con la Tarjeta de Crédito Titular y sus
                      Tarjetas Adicionales, no obstante, éstas últimas sólo sumarán Puntos Macro Premia en la cuenta del
                      Titular. Los productos, beneficios o servicios podrán ser solicitados por los titulares.
                      Se considerarán los consumos y/o débitos automáticos realizados en la República Argentina y en el
                      exterior, tanto en moneda nacional como en moneda extranjera, que ingresen en cada cierre de
                      liquidación de la Tarjeta de Crédito. Los Puntos Macro Premia serán acreditados dentro de los 3 (tres)
                      días posteriores al cierre de liquidación de la Tarjeta de Crédito. No suman puntos los saldos
                      financiados, cargos de resumen, cargos de renovación de tarjeta, gastos de mantenimiento,
                      intereses, adelantos en efectivo, cargos negativos (reversión de transacciones u otros cargos) y todo
                      otro concepto que no corresponda a un consumo. En el caso de reversos de consumos y/u
                      operaciones, contracargos y devoluciones los puntos que pudieron haberse acumulado se eliminan.</span></span></p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>2.2</strong> &nbsp;Los Puntos Macro Premia son propiedad de Banco Macro, intransferibles a otra cuenta, persona
                      o entidad bajo ningún título o causa y no pueden ser canjeados por dinero y perecen o caducan en
                      caso de cancelación del Programa Macro Premia o al momento del vencimiento de los mismos, lo
                      que suceda primero.
                      </span></span></p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>2.3</strong> Banco Macro no se responsabiliza por los cupones que hayan sido presentados fuera de término
                      por los establecimientos adheridos al sistema de Tarjetas de Crédito o por aquellos cupones
                      rechazados por cualquier motivo.</span></span></p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>2.4</strong> &nbsp;Banco Macro se reserva el derecho de realizar promociones periódicas que impliquen la
                      asignación adicional de Puntos Macro Premia a los Clientes.</span></span></p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>2.5</strong> El Programa Macro Premia y el Programa Aerolíneas Plus no son acumulables, no obstante los
                      Puntos Macro Premia podrán canjearse por Millas Aerolíneas Plus y asimismo las Tarjetas de Crédito
                      adheridas al Programa Aerolíneas Plus podrán adquirir Beneficios en Macro Premia mediante la
                      modalidad Full Pesos.</span></span></p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>2.6</strong> En caso que un Cliente haya solicitado la baja de Macro Premia y la adhesión al Programa
                      Aerolíneas Plus, en lo sucesivo dejará de sumar Puntos Macro Premia y comenzará a sumar millas;
                      en dicho caso si el Cliente tuviera Puntos Macro Premia acumulados, éstos dejarán de ser
                      informados en el resumen de la Tarjeta de Crédito y conservarán una vigencia de 12 (doce) meses
                      o de 6 (seis) meses en caso de inactividad en Macro Premia, lo que suceda primero, a partir de la
                      adhesión al Programa Aerolíneas Plus; consecuentemente los Puntos Macro Premia que no fueren
                      canjeados durante su vigencia caducarán automáticamente.</span></span></p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>2.7</strong> Forma de cálculo: Por cada $ 600,00 (seiscientos pesos argentinos) correspondientes a
                      consumos y/o débitos automáticos, se acumulará 1 (un) Punto Macro Premia. Por cada US$ 600
                      (seiscientos dólares estadounidenses) de consumos y/o débitos automáticos se acumula un Punto
                      Macro Premia. En los consumos financiados en cuotas, se considerará el importe de la cuota
                      correspondiente al cierre mensual de la tarjeta de Crédito.</span></span></p>
                </div>
            </div>
        </div>
        <!-- End Section -->
        <!-- Section -->
        <div class="card">
            <div class="card-header p-0" id="group-3">
                <h2 class="mb-0">
                    <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                        3. VIGENCIA
                        <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                    </button>
                </h2>
            </div>

            <div class="collapse" id="collapse-3">
                <div class="card-body sub-title-macro">
                    <p style="text-align:justify">
                        <span style="font-size:12px">
                            <span>
                              Los Puntos Macro Premia tendrán una vigencia de dos (2) años, desde el último consumo y/o de
                              ingreso de débito automático; no obstante en caso de cumplirse 6 (seis) meses de inactividad en
                              Macro Premia, todos los Puntos Macro Premia acumulados por el Cliente caducarán y serán
                              eliminados sin que ello otorgue derecho a indemnización o reclamo de ningún tipo. Se considera
                              inactividad tanto a la no acumulación de Puntos Macro Premia, como la no redención de canje de
                              Puntos Macro Premia.
                            </span>
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <!-- End Section -->
        <!-- Section -->
        <div class="card">
            <div class="card-header p-0" id="group-4">
                <h2 class="mb-0">
                    <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                        4. MODALIDADES DE CANJE Y/O ADQUISICI&Oacute;N DE BENEFICIOS
                        <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                    </button>
                </h2>
            </div>

            <div class="collapse" id="collapse-4">
                <div class="card-body sub-title-macro">
                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>4.1</strong> &nbsp;Los Beneficios podrán ser canjeados y/o adquiridos bajo las siguientes modalidades: <strong>CANJE DE
                      PUNTOS MACRO PREMIA:</strong> Consiste en la adquisición de Beneficios mediante canje íntegro de
                      Puntos macro Premia. Será requisito tener acumulados suficientes Puntos Macro Premia
                      equivalentes al Beneficio elegido. <strong>PUNTOS + PESOS:</strong> Consiste en el canje de Puntos Macro Premia
                      acumulados, más una suma en pesos a abonar únicamente con las Tarjetas de Crédito, por ello será
                      requisito haber acumulado Puntos Macro Premia suficientes para el canje y abonar mediante la
                      Tarjeta de Crédito, la suma determinada en pesos correspondiente al Beneficio elegido. <strong>FULL PESOS:</strong>
                      Consiste en la adquisición de Beneficios se realiza íntegramente mediante el abono de la suma
                      determinada en pesos correspondiente al Beneficio elegido, con la Tarjeta de Crédito. Esta modalidad
                      no requiere contar con Puntos Macro Premia acumulados. Precio publicado para consumidor final.</span></span></p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>4.2</strong> &nbsp;Cada vez que el Cliente solicite el canje y/o adquisición de Beneficios, le serán descontados los
                      Puntos Macro Premia correspondientes y adicionalmente, en las modalidades PUNTOS+PESOS o Full
                      Pesos se generará el correspondiente consumo en pesos (bajo las condiciones informadas en el
                      catálogo para dicho Beneficio) en la Tarjeta de Crédito seleccionada como medio de pago, dentro
                      de las Tarjetas de Crédito que posea el Cliente consideradas válidas al momento de la adquisición del
                      Beneficio. Dicho consumo se verá reflejado en el primer resumen de la Tarjeta de Crédito posterior
                      a la adquisición del Beneficio o en los resúmenes posteriores que correspondiesen, para el caso de
                      financiación en cuotas.&nbsp;</span></span></p>
                </div>
            </div>
        </div>
        <!-- End Section -->
        <!-- Section -->
        <div class="card">
            <div class="card-header p-0" id="group-5">
                <h2 class="mb-0">
                    <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                        5. BENEFICIOS
                        <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                    </button>
                </h2>
            </div>

            <div class="collapse" id="collapse-5">
                <div class="card-body sub-title-macro">
                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>5.1</strong><strong> </strong>&nbsp;Se denominan Beneficios a todos aquellos productos, servicios y/o beneficios que el Cliente
                      podrá adquirir bajo las modalidades disponibles en Macro Premia.</span></span></p>
                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>5.2</strong>&nbsp;El Cliente podrá elegir entre los Beneficios que figuren en el catálogo de Beneficios Macro
                      Premia disponible en Banca Internet, donde se detallarán cada uno de los Beneficios y su
                      correspondiente puntaje/valor en pesos con vigencia dentro del territorio de la República Argentina.
                      Las imágenes incluidas en el catálogo serán meramente ilustrativas, las características,
                      especificaciones técnicas y garantías serán de exclusiva responsabilidad de fabricantes,
                      distribuidores o proveedores de los mismos. Asimismo, los Beneficios podrán ser comunicados a
                      través de folletos y/o catálogos, afiches u otro material publicitario gráfico similar disponible o
                      expuesto en las sucursales del Banco, a través de Banca Internet, en <a href="http://www.macro.com.ar">www.macro.com.ar</a> y/o App
                      Macro.</span></span>
                    </p>

                    <p style="text-align:justify"><span style="font-size:12px"><span>Los Beneficios están sujetos a las modalidades de canje y/o adquisición informados, así como a su
                      disponibilidad. Una vez agotado el stock de Beneficios (de cualquier tipo), Banco Macro no estará
                      obligado a reposición de stock ni a cambio por otros Beneficios, asimismo no asume ningún tipo de
                      responsabilidad por la no disponibilidad de los Beneficios, cualquiera fuera la causa. El catálogo de
                      productos, beneficios o servicios del Programa tendrá vigencia hasta agotar stock de los mismos. El
                      stock vigente puede consultarse en Banca Internet o en el portal del Banco <a href="http://www.macro.com.ar">www.macro.com.ar</a>.&nbsp;</span></span></p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>5.3</strong> Los Beneficios correspondientes a Despegar (en adelante “Productos Despegar”) serán
                      exclusivamente comunicados y canjeados a través del sitio web de Macro Premia de Despegar, al
                      cual se podrá ingresar a través de Banca Internet en <a href="http://www.macro.com.ar">www.macro.com.ar</a>. Los mismos se regirán por
                      los Términos y Condiciones del Sitio Macro Premia de Despegar que se adjuntan como Anexo a los
                      presentes. En caso de discrepancia entre los Términos y Condiciones de Macro Premia y los
                      Términos y Condiciones del Sitio Macro Premia de Despegar, exclusivamente para Productos
                      Despegar, prevalecerán estos últimos.</span></span>
                    </p>

                    <p style="text-align:justify"><span style="font-size:12px"><span>Los Beneficios que consisten en vuelos, autos, paquetes, actividades, alojamiento y micros son
                      ofrecidos por “DESPEGAR.COM”CUIT 30701307115. No será responsabilidad de Banco Macro
                      cualquier cambio y/o modificación que se disponga por parte de la aerolínea y/o del agente de viaje;
                      cualquier cambio deberá ser gestionado por el Cliente ante la agencia y/o aerolínea respectiva,
                      Banco Macro no será responsable por las gestiones de cambios o modificaciones que el Cliente
                      realice con la operadora de viajes o la aerolínea una vez adquirido su pasaje a través de Macro
                      Premia. &nbsp;</span></span>
                    </p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>5.4</strong> En los Beneficios que consisten en entradas para recitales u otros eventos similares, Banco
                      Macro no será responsable por la suspensión del mismo, ni estará obligado a restituir los Puntos
                      Macro Premia en caso de cancelación, resultando ajeno a cualquier consecuencia que pudiera
                      derivarse de tal evento.</span></span>
                    </p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>5.5</strong>&nbsp;Los Beneficios, conforme se detalle, en Macro Premia, podrán ser enviados al mail que el Cliente
                      previamente informe, ser retirados personalmente por el Cliente o quien éste designe, en los
                      puntos de entrega y/o enviados de manera gratuita en caso que apliquen estas condiciones.</span></span>
                    </p>

                    <p style="text-align:justify"><span style="font-size:12px"><span>Aquellos con envío gratuito, serán entregados en un plazo no mayor a 15 (quince) días hábiles
                      posteriores al canje y/o adquisición del mismo; si por circunstancias ajenas a Banco Macro se
                      excediera este plazo, se realizarán las gestiones tendientes a cumplimentar con la entrega sin que
                      esto implique asunción de responsabilidad alguna por la demora ocurrida. La recepción de los
                      Beneficios la podrá realizar el Titular de la Tarjeta de Crédito u otr a persona mayor a 14 años, la
                      cual deberá firmar el acuse de recibo correspondiente. La entrega la realizará Ocasa Soluciones
                      Logísticas (“Ocasa”) en el domicilio indicado por el Cliente. Ocasa realizará hasta 2 (dos) visitas en
                      días correlativos en distintos horarios cada uno, salvo que el Cliente se comunique con Ocasa y pacte
                      una fecha y horario, luego el Beneficio reingresará al stock, se realizará la devolución de los Puntos
                      Macro Premia implicados y de corresponder, en las modalidades PUNTOS+PESOS o FULL PESOS,
                      asimismo se revertirá el consumo en la Tarjeta de Crédito del Cliente, la responsabilidad por el envío
                      por parte de Banco Macro se agota con la recepción del Beneficio en el domicilio determinado por
                      el Cliente, por ello Banco Macro no se responsabiliza ni asume cualquier otro gasto en que pueda
                      incurrir el Cliente o que pueda generarse con posterioridad a dicha entrega.</span></span>
                    </p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>5.6</strong><strong> </strong>&nbsp;Aceptar los Beneficios implica la autorización tácita de Clientes a Banco Macro para difundir o
                      publicar sus nombres y/o divulgar sus imágenes, y/o divulgar sus fotografías y/o divulgar su voz con
                      fines publicitarios en los medios y en las formas que considere correctas, sin derecho de
                      compensación alguna.</span></span>
                    </p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>5.8</strong><strong> </strong>&nbsp;Para efectuar reclamos, los Clientes se deberán contactar con el Centro de Atención Telefónica
                      (0-810-555-2355). Los beneficios consistentes en servicios que sean prestados por terceros, serán
                      de exclusiva responsabilidad de los prestadores, asimismo Banco Macro no asume responsabilidad
                      ni extiende garantía alguna por fallas, falencias, errores, daños o cualquier tipo de inconveniente
                      relativo a los Beneficios, no obstante si el reclamo corresponde a fallas, roturas, errores,
                      desperfectos o falencias y se efectúa dentro de los 10 (diez) días siguientes de haber recibido el
                      Beneficio, Banco Macro limitará su responsabilidad a gestionar con el proveedor la reposición.
                      Superado el plazo precedentemente establecido, el cliente deberá hacer uso de la garantía del
                      producto reclamando a la marca del mismo. Para ello debe contar con la factura tanto de compra
                      como adquisición que será enviada por mail al momento de la gestión de la solicitud.</span></span></p>
                </div>
            </div>
        </div>
        <!-- End Section -->
        <!-- Section -->
        <div class="card">
            <div class="card-header p-0" id="group-6">
                <h2 class="mb-0">
                    <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
                        6. ASPECTOS GENERALES
                        <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                    </button>
                </h2>
            </div>

            <div class="collapse" id="collapse-6">
                <div class="card-body sub-title-macro">
                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>6.1</strong> Banco Macro podrá modificar total o parcialmente los presentes Términos y Condiciones sin
                      necesidad de comunicación previa de ningún tipo; asimismo se arroga la facultad de modificar total
                      o parcialmente o aún suspender temporaria o definitivamente el programa Macro Premia en
                      cualquier momento, sin necesidad de justificar dicha resolución.</span></span></p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>6.2</strong> Todas las preguntas o asuntos concernientes a Macro Premia serán resueltas por Banco Macro.</span></span></p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>6.3</strong>&nbsp; La participación en Macro Premia implica la aceptación incondicional de estos Términos y
                      Condiciones, así como de las decisiones que adopte Banco Macro sobre cualquier cuestión no
                      prevista en ellos, entendiéndose que el hecho que Banco Macro omita la exigencia de un término o
                      condición en particular, no constituye una renuncia por parte de Banco Macro a dicho término o
                      condición.</span></span>
                    </p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>6.4</strong>&nbsp;Para mayor información, el Cliente podrá contactarse con el Centro de Atención Telefónica al
                      0810-555- 2355 de lunes a viernes de 07:00 a 20:00 hs.</span></span></p>
                </div>
            </div>
        </div>
        <!-- End Section -->
        <!-- Section -->
        <div class="card">
            <div class="card-header p-0" id="group-7">
                <h2 class="mb-0">
                    <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-7" aria-expanded="false" aria-controls="collapse-7">
                        7. Anexo - Sitio Macro Premia de Despegar
                        <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                    </button>
                </h2>
            </div>

            <div class="collapse" id="collapse-7">
                <div class="card-body sub-title-macro">
                    <p style="text-align:center"><span><span style="font-size:16px"><strong>T&eacute;rminos y Condiciones </strong></span></span></p>

                    <p>&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span>Los presentes Términos y Condiciones serán aplicables para la reserva de productos turísticos
                      (“Productos Despegar”) ofrecidos a través de la página web diseñada y administrada especialmente
                      por Despegar (“Sitio”) para la utilización de los puntos acumulados (“Puntos Macro Premia”) por los
                      clientes (“Usuarios”) de Banco Macro S.A. (el “Banco”) bajo el programa Programa Macro Premia
                      (“Macro Premia”).</span></span>
                    </p>

                    <p style="text-align:justify"><span style="font-size:12px"><span>Despegar.com.ar s.a., CUIT 30-70130711-5, con domicilio en Av. Jujuy 2013, Ciudad Autónoma de
                      Buenos Aires (“Despegar”), es una agencia de turismo que ofrece servicios turísticos online,
                      debidamente autorizada en la República Argentina para la prestación de servicios turísticos e
                      inscripta bajo el legajo EVT 10680 (Resolución Nº 821/2008 de la Secretaría de Turismo).
                    </span></span></p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>1.</strong>&nbsp;Los presentes Términos y Condiciones son complementarios de: (i) los Términos y Condiciones
                      del Programa Macro Premia; (ii) los Términos y Condiciones de los Productos Despegar reservados
                      por cada Usuario; (iii) la Política de Privacidad y Protección de Datos Personales publicada en el Sitio
                      y (iv) los Términos y Condiciones particulares de cada proveedor final de cada Producto Despegar.&nbsp;</span></span>
                    </p>

                    <p style="text-align:justify"><span style="font-size:12px"><span>Los Usuarios deberán leer detenidamente y aceptar los documentos enumerados antes de realizar
                      cualquier compra en el Sitio. La utilización de Puntos Macro Premia para comprar Productos
                      Despegar en el Sitio implica el conocimiento y aceptación de todas las condiciones establecidas en
                      estos Términos y Condiciones, así como de los restantes documentos enumerados.</span></span>
                    </p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>2.</strong>&nbsp;La acumulación de Puntos Macro Premia bajo el Programa Macro Premia, así como toda cuestión
                      relacionada es determinada exclusivamente por Banco Macro, de acuerdo a los Términos y
                      Condiciones del Programa Macro Premia.</span></span>
                    </p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>3.</strong>&nbsp;Los Puntos Macro Premia podrán ser utilizados para el canje de Productos Despegar disponibles
                      en el Sitio, el cual se encontrará accesible únicamente a través de la plataforma Banca Internet
                      (Home Banking) o App Macro los Puntos Macro Premia no podrán ser canjeados en el sitio web de
                      Despegar (www.despegar.com), ni telefónicamente, ni mediante ningún otro canal distinto del Sitio.</span></span>
                    </p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>4.</strong>&nbsp;. El Usuario podrá seleccionar un Producto Despegar en el Sitio y abonar total o parcialmente su
                      precio con Puntos Macro Premia. Al momento de cotizar un Producto Despegar, el Sitio le informará
                      al Usuario la cantidad de Puntos Macro Premia requeridos a los fines de su compra. De existir una
                      diferencia entre el valor de los Puntos Macro Premia que el Usuario pretenda utilizar y el precio del
                      Producto Despegar seleccionado, se informará al Usuario el importe en moneda de curso legal que
                      corresponda a dicha diferencia. El Usuario podrá abonar dicha diferencia con cualquiera de los
                      medios de pago disponibles en el Sitio.</span></span>
                    </p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>5.</strong>&nbsp;La diferencia entre el valor de los Puntos Macro Premia que el Usuario pretenda utilizar y el precio
                      del Producto Despegar seleccionado: a. No podrá ser pagada con ningún voucher o cupón de
                      descuento; y b. Sólo podrá ser pagada con las tarjetas de crédito emitidas por el Banco que estén
                      disponibles como medios de pago en el Sitio.</span></span>
                    </p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>6.</strong>&nbsp;Con excepción de los pasajes aéreos, para los restantes Productos Despegar, los Puntos Macro
                      Premia podrán asimismo ser utilizados para abonar los costos que no estén incluidos en la tarifa
                      base (impuestos, tasas, cargos de servicio, percepción prevista por la Resolución General 3450 de la
                      AFIP, entre otros) (los “Costos Adicionales”). Sin embargo, para el caso de los pasajes aéreos
                      incluidos en los Productos Despegar, de acuerdo a las limitaciones impuestas por las líneas aéreas,
                      los Puntos Macro Premia no podrán ser utilizados para abonar de manera parcial los Costos
                      Adicionales, aunque sí podrán ser utilizados para abonar tales Costos Adicionales en tanto los
                      mismos sean abonados en su totalidad con Puntos Macro Premia. A modo de ejemplo, si el Costo
                      Adicional de un pasaje aéreo es de $ 5.000, el Usuario podrá pagar los $ 5.000 de Costo Adicional o
                      bien con Puntos Macro Premia o bien con otro medio de pago disponible en el Sitio, pero no podrá
                      pagar –respecto de los Costos Adicionales– (por ejemplo) $ 2.500 con Puntos Macro Premia y $
                      2.500 con otro medio de pago.</span></span>
                    </p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>7.</strong>&nbsp;La cancelación o modificación de las reservas de Productos Despegar se podrá realizar
                      únicamente a través del Sitio. La forma de ejercer dicha posibilidad se rige por los Términos y
                      Condiciones particulares de cada proveedor final del Producto Despegar. Si la política de
                      cancelación del proveedor final del Producto Despegar admite el reembolso total o parcial, y el
                      Usuario ha realizado la solicitud de la manera adecuada, el reembolso se tramitará de la siguiente
                      manera:</span></span>
                    </p>

                    <ol>
                        <li style="text-align:justify; margin-left: 10px; margin-bottom: 10px;"><span style="font-size:12px"><span>De ser un pago total con Puntos Macro Premia, previo descuento de la penalidad que
                          corresponda (en caso de corresponder), se devolverá el proporcional en Puntos Macro Premia.
                          La devolución será efectuada tomando como paridad para el cómputo de los Puntos Macro
                          Premia, la misma utilizada al momento del canje del Producto Despegar.</span></span>
                        </li>
                        <li style="text-align:justify; margin-left: 10px; margin-bottom: 10px;"><span style="font-size:12px"><span>De haber efectuado el pago parcialmente con Puntos Macro Premia y parcialmente con otro
                          medio de pago, de corresponder una penalidad se cobrará de los Puntos Macro Premia y se
                          devolverá el proporcional en Puntos Macro Premia y el otro medio de pago seleccionado (en
                          caso de corresponder).</span></span>
                        </li>
                        <li style="text-align:justify; margin-left: 10px; margin-bottom: 10px;"><span style="font-size:12px"><span>En caso que el Usuario efectuara una modificación de alguno de los Productos Despegar
                          adquiridos a través del Sitio, y, a consecuencia de esa modificación, debiera abonar una
                          diferencia, la misma no podrá ser pagada con Puntos Macro Premia.</span></span>
                        </li>
                        <li style="text-align:justify; margin-left: 10px;"><span style="font-size:12px"><span>En cualquier caso, la devolución de Puntos Macro Premia se verá reflejada en la cuenta del
                          Usuario bajo el Programa Macro Premia dentro de los 120 (ciento veinte) días desde realizada
                          la cancelación o modificación de su compra. En el caso de los tickets aéreos, la devolución se
                          efectuará en el momento en que la línea aérea contratada así lo disponga. Este proceso es
                          externo a Despegar y, como tal, Despegar no hace aseveraciones respecto de los plazos en los
                          cuales la línea aérea hará la devolución. El Cliente no podrá solicitar que las devoluciones se
                          realicen en dinero u otros bienes y/o servicios distintos de los Puntos Macro Premia.</span></span>
                        </li>
                    </ol>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>8.</strong>&nbsp;. Cualquier acción que mediante el uso de dispositivos, software u otros instrumentos tienda a
                      interferir y/o vulnerar, la seguridad de los sistemas y de la operatoria del Sitio y/o del sistema de
                      comunicación entre el Banco y Despegar sobre los Puntos Macro Premia acumulados por el Usuario,
                      será considerada ilícita y hará pasible a su responsable de las acciones legales pertinentes y de las
                      sanciones previstas por estos Términos y Condiciones.</span></span>
                    </p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="font-size:12px"><span><strong>9.</strong>&nbsp;Estos Términos y Condiciones han sido redactados en conjunto por el Banco y por Despegar y se
                      regirán por las leyes de la República Argentina. Cualquier controversia que se suscite entre las partes
                      con relación a la existencia, validez, interpretación o cumplimiento de estos Términos y Condiciones
                      se resolverá en forma exclusiva y definitiva por los Tribunales Ordinarios en materia comercial de la
                      Ciudad Autónoma de Buenos Aires.</span></span>
                    </p>
                </div>
            </div>
        </div>
        <!-- End Section -->
    </div>
    <!-- End Accodion FAQs -->


  </div>

