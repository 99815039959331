import { Component, OnInit } from '@angular/core';
import { CatalogService } from '../../services/catalog.service';
import { Catalog } from 'models/models.model';
import { environment } from 'environments/environment';
import { MktService } from '../new-core/core/services/mkt.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.sass']
})
export class ErrorPageComponent implements OnInit {
  catalog: Catalog;
  env = environment

  constructor(
    private _mktService: MktService,
    private catalogService: CatalogService
    ) { }

  ngOnInit() {
    this._mktService.setMetaTags('Ups, nada por aquí');
    this.randomVariants();
  }
/*
  async randomVariants() {
    await this.catalogService.find( '', new HttpParams ).subscribe(
      (response) => {
        const cat = response;
        cat.variants = cat.variants.splice(0,4);
        console.log(cat)
        this.catalog = cat;
      }
    );
  }*/
  randomVariants(){
    this.catalogService.find(undefined, {query: ""}).subscribe(
      (response) => {
        console.log(response)
        this.catalog = response;
      },
    );
  }
}
