import { Component, OnInit } from '@angular/core';
import { UrlService } from 'services/url.service';

@Component({
  selector: 'app-turismo',
  templateUrl: './turismo.component.html',
  styleUrls: ['./turismo.component.sass']
})
export class TurismoComponent implements OnInit {

  constructor(private urlService: UrlService) { }

  ngOnInit() {
    this.urlService.navigate('http://www-qa2.ola.com.ar/transa2016/inicio.php?utm_source=avenida');
  }

}
