<div class="container" style="width: calc(100% + 30px); margin-left: -15px ;">

  <div class="col-lg-12 cols5-carousel px-0" *ngIf="component">
    <div class="articles-list w-100 carousel-overflow" [id]="uniqueId" #container style="padding-left: 15px;">
      <article style="margin-right: 0.8rem;" class="pointer" *ngFor="let item of component.data.items; let i = index">
        <a (click)="navigate(item.link)" class="link pointer">
          <!--<img [lazyLoad]="item.image" alt="" [offset]="100" [scrollTarget]="(i >= 2 && windowWidth <= 768) ? container : window">-->
          <img src="{{item.image}}" alt="image item">
        </a>
        <div *ngIf="item.title">
          <h2>{{ item.title }}</h2>
        </div>
      </article>
    </div>
  </div>

</div>
