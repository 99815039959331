<div id="suggestion" *ngIf="suggestions">

  <h6 class="pt-3 px-4 text-muted">Categorías</h6>

  <div class="px-4">
    <p class="my-2"><small>Buscar <b>"{{ searchText }}"</b> en:</small></p>
    <span (click)="clearSuggestion()" [routerLink]="['/catalog', option.url]" class="c-pointer badge badge-secondary mr-2 mb-2 px-2" *ngFor="let option of limitTo(suggestions.suggestions.categories, 4)">{{ option.name }}</span>
  </div>

  <hr class="mx-4">

  <h6 class="mb-3 px-4 text-muted">Productos</h6>

  <div class="list-group">
      <a  (click)="clearSuggestion()" [routerLink]="['/products', option.product.slug]"
          class="list-group-item list-group-item-action"
          [ngClass]="{'on-sale': option?.product.is_on_sale}"
          *ngFor="let option of limitTo(suggestions.suggestions.variants, 4)">

        <div class="row">
          <!-- Product Image -->
          <div class="col-2 px-2">
            <img [src]="option.picture_thumb" class="img-fluid rounded d-block mx-auto" style="max-width:80%;" alt="{{option.product.title}}">
          </div>

          <!-- Product Details -->
          <div class="col-10 pl-2">
            <div class="d-flex w-100 justify-content-between">
              <h6 class="mb-1 w-75 text-truncate font-weight-bold">{{option.product.title}}</h6>
              <small class="badge badge-primary" style="padding-top: 6px;" *ngIf="option.product.is_on_sale">{{ option.product.percent_off }}% Off</small>
            </div>

            <div *ngIf="this.showPrice(option)">
              <p class="mb-1 w-100">
                <span class="price" [ngClass]="{'sale-price': !option?.product.is_on_sale }" *ngIf="option.product.regular_price">
                  {{ store == 'nuuv' ? 'u$s' : '$'}} {{ floatParse(option.product.regular_price) }}
                </span>
                <span class="sale-price" *ngIf="option.product.is_on_sale">
                  {{ store == 'nuuv' ? 'u$s' : '$'}} {{ floatParse(option.product.sale_price) }}
                </span>
                <span class="sale-price points-price" *ngIf="option.product.points">
                  | {{ floatParse(option.product.points) }} Puntos
                </span>
              </p>
              <small style="font-size: 10px;" *ngIf="env.installments > 1 && option.product.transaction_type !== 'voucher'">
                Comprá en cuotas
              </small>
            </div>

            <div *ngIf="option.product.transaction_type === 'voucher' || option.product.transaction_type === 'points' || option.product.transaction_type === 'cashback'">
              <small>Aprovechá estos beneficios usando tus puntos.</small>
            </div>

          </div>

        </div>
      </a>
  </div>

  <div class="title-more mx-4 pb-3" (click)="searchCatalog()"><small>Ver más resultados</small></div>
</div>
