//Angular
import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import { Router } from '@angular/router';
//Models
import { Customer } from 'models/models.model';
//Services
import { CustomerService } from 'services/customer.service';
import { AddressesService } from 'services/account/addresses.service';
import { AuthenticationService } from 'services/authentication.service';

@Component({
  selector: 'dialog-login',
  templateUrl: './login.dialog.component.html',
  styleUrls: ['./login.dialog.component.sass']
})

export class LoginDialogComponent implements OnInit {


  constructor(
              private router: Router,
              private dialogRef: MatDialogRef<LoginDialogComponent>,
              private authenticationService: AuthenticationService,
              private addressesService: AddressesService,
              private customerService: CustomerService) { }

  ngOnInit() {
    if (this.authenticationService.isExternalLogin()) {this.router.navigate(['/'])}
    this.authenticationService.authentication.subscribe(
      (value: boolean) => {
        this.customerService.init().subscribe(
          (response: Customer) => {
            this.customerService.setCustomer(response);
            this.addressesService.broadcastUpdate();
          }
        );
        this.dialogRef.close();
      }  
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
