import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SubeService } from 'services/sube.service';


@Component({
  selector: 'app-edit-card',
  templateUrl: './edit-card.component.html',
  styleUrls: ['./edit-card.component.sass']
})
export class EditCardComponent implements OnInit {
  
  selectEditCard: FormGroup;
  isLoaded = false;
  newCard:boolean;
  cards: object;
  selectedCard: number;
  errors: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private subeService: SubeService,
              private _formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<EditCardComponent>) { }
  ngOnInit() {
    this.isLoaded = true;
    this.formsGenerator();
    this.getCards();
  }

  async getCards() {
    await this.subeService.getCards().toPromise()
      .then((response)=>{
        this.isLoaded = true;
        this.cards = response;
        (response.length > 0) ? this.newCard = false : this.newCard = true;
      })
      .catch((error)=>{
        this.isLoaded = false;
      })
  }

  formsGenerator() {
    this.selectEditCard = this._formBuilder.group({
      'selectionCard': new FormControl(null, [
        Validators.required
      ]),
      'alias': new FormControl(null, [
        Validators.required
      ]),
      'number': new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/i),
        Validators.min(16)
      ]),
      'confirm': new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/i),
        Validators.min(16)
      ]),
    });
  }

  validator() {
    if (this.selectEditCard.value == null) {
      this.errors = "Complete todos los campos"
    }
    if (this.selectEditCard.getRawValue().number !== this.selectEditCard.getRawValue().confirm) {
      this.errors = "El número y la confirmación no coinciden"
      return false;
    } 
    return true;
  }

  editCard() {
    if (this.selectEditCard.valid) {

      if (this.validator()) {
        const card_id = this.selectEditCard.value.selectionCard.id;
        const card_data = this.selectEditCard.value;
        this.subeService.editCard(card_id, card_data).subscribe(
          (response) => {
            this.closeDialog();
          },
          (error) => {
            this.errors = "Ha ocurrido un error al intentar editar la tarjeta"
          }
        );
      }
    }
  }

  updateForm(name, number) {
    this.selectEditCard.patchValue({
      alias: name, 
      number: number,
      confirm: number
    });
    
  }

  addNewCard() {
    this.newCard = true;
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
