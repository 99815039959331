import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import { environment } from 'environments/environment';

@Component({
  selector: 'app-phone-dialog',
  templateUrl: './phone-dialog.component.html',
  styleUrls: ['./phone-dialog.component.sass']
})
export class PhoneDialogComponent implements OnInit {

  env = environment;

  constructor(private dialogRef: MatDialogRef<PhoneDialogComponent>) { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
