import { Injectable, OnInit } from '@angular/core';
//Services
import { UrlService } from './url.service';
import { HttpService } from './http.service';
import { StoreService } from './store.service';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AerolineasArgentinasService implements OnInit {

  storeId;

  constructor(
    private urlService: UrlService,
    private http: HttpService,
    private storeService: StoreService,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.storeService.getStore().subscribe(res => this.storeId = res.id);

  }


  notLoggedIn() {
    if (!this.authenticationService.isLoggedIn()) this.urlService.navigate("https://www.macro.com.ar/bancainternet/");
  }



  getClient(params) {
    return this.http.post('aerolineas_argentinas/frequent_traveler_information', {
      document_type: params.docType,
      document_number: params.docNumber,
      store_id: params.store_id,
    });
  }

  acredit(params) {
    let aerolineas_argentina = {
      document_type: params.docType,
      document_number: params.docNumber,
      store_id: params.store_id,
      points: params.points,
      email: params.email
    }
    return this.http.post('integration/points_exchange', {exchange: aerolineas_argentina, gateway: {name: "aerolineas"}});
  }


}
