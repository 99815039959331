<div *ngIf="env.variantCard === 0; then classic else modern"></div>

<ng-template #classic>
  <article class="card w-100" style="min-width: 100%;" [ngClass]="{'on-sale': variant?.product.is_on_sale}" [routerLink]="['/products', variant.product.slug]">

    <span class="sale-discount" *ngIf="variant.product.is_on_sale">
      <span>{{ variant.product.percent_off }}% OFF</span>
    </span>

    <a  style="background-repeat: no-repeat; background-position: center;"
        class="img d-block"
        [routerLink]="['/products', variant.product.slug]"
        (click)="trackProductClick()"
        [lazyLoad]="variant.picture_medium_l"
        title="{{ variant.product.title }}"
        [offset]="100">
    </a>

    <div class="card-body d-flex flex-column">
      <div class="title-panel d-block">
        <h6 class="d-inline-block w-100">{{ variant.product.title }}</h6>
      </div>
      <div class="manufacturer-panel">
        <h6>{{ manufacturerTitle }}</h6>
      </div>
      <div class="shop-panel">
        <h6>{{ shopTitle }}</h6>
      </div>
      <div class="info-panel mt-auto">
        <div #money *ngIf="variant.product.transaction_type === 'reservable' || variant.product.transaction_type === 'purchasable';">
          <span class="price" [ngClass]="{'sale-price': !variant?.product.is_on_sale }" *ngIf="variant.product.regular_price">
            {{ store == 'nuuv' ? 'u$s' : '$'}} {{ floatParse(variant.product.regular_price) }}
          </span>
          <span class="sale-price ml-1" *ngIf="variant.product.is_on_sale">
            {{ store == 'nuuv' ? 'u$s' : '$'}} {{ floatParse(variant.product.sale_price) }}
          </span>
          <span class="installments" *ngIf="env.installments > 1">
            <span *ngIf="store === 'Avenida'; then installmentsGeneric else installmentsAmount"></span>
            <ng-template #installmentsGeneric>
                <small>Comprá en cuotas</small>
            </ng-template>
            <ng-template #installmentsAmount>

              <div *ngIf="hasInstallments">
                <small [ngClass]="env.name === 'Macro' ? 'sub-title-macro' : '' ">Hasta {{ buildInstallments() }} {{ buildInstallmentsText() }} </small>
                <br>
                <span *ngIf="store !== 'Macro'" class="amount cost">${{ floatParse(buildInstallmentsAmount()) }}</span>
                <span *ngIf="store === 'Macro'" class="amount cost">${{ floatParseToInt(buildInstallmentsAmount())}}</span>
              </div>
            </ng-template>
          </span>
          <span class="points-and-price" *ngIf="variant.product.points">
            <hr>
          {{ store == 'Macro' ? floatParse(macroPoints) : floatParse(variant.product.points) }} Puntos {{ store == 'Macro' && variant.product.is_on_sale ? ' + $' + floatParse(salePrice) : ' + $' + floatParse(regularPrice) }}
          </span>
        </div>
        <div #points *ngIf="variant.product.transaction_type === 'voucher' || variant.product.transaction_type === 'points' || variant.product.transaction_type === 'cashback';">
          <span class="points-price" *ngIf="minPoints">{{ onlyVariant ? "" : "Desde" }} {{ floatParse(minPoints) }} Puntos</span>
        </div>
      </div>

    </div>
  </article>
</ng-template>


<ng-template #modern>
  <article id="modern-variant-card" class="card w-100" style="min-width: 100%;" [ngClass]="{'on-sale': variant?.product.is_on_sale}" [routerLink]="['/products', variant.product.slug]">

    <span class="badge on-sale" *ngIf="variant.product.is_on_sale">
      <span>{{ variant.product.percent_off }}% OFF</span>
    </span>

    <a  style="background-repeat: no-repeat; background-position: center;"
        class="img d-block"
        [routerLink]="['/products', variant.product.slug]"
        (click)="trackProductClick()"
        [lazyLoad]="variant.picture_medium_l"
        title="{{ variant.product.title }}"
        [offset]="100">
    </a>

    <div class="card-body d-flex flex-column text-left">
      <div class="title-panel d-block">
        <h6 class="d-inline-block">{{ variant.product.title | titlecase }}</h6>
        <h3 class="m-0">
          <span class="badge badge-light" *ngIf="variant.quantity <= 4"><i class="fa fa-warning mr-1"></i>Quedan Pocos</span>
        </h3>
      </div>
      <div class="manufacturer-panel d-block mt-auto">
        <h6>{{ manufacturerTitle }}</h6>
      </div>
      <div class="shop-panel d-block mt-auto">
        <h6>{{ shopTitle }}</h6>
      </div>
      <div class="info-panel mt-auto">
        <div #money *ngIf="variant.product.transaction_type === 'reservable' || variant.product.transaction_type === 'purchasable';">
          <span class="installments mb-2 mt-2" *ngIf="env.installments > 1">
            <span *ngIf="store == 'Avenida'; then installmentsGeneric else installmentsAmount"></span>
            <ng-template #installmentsGeneric>
                <small><i class="fa fa-credit-card text-muted mr-1"></i>Comprá en cuotas</small>
            </ng-template>
            <ng-template #installmentsAmount>

              <small *ngIf="use_bines && env.name !== 'EspecialesBNA'">
                  Hasta {{ buildInstallments() }} {{ buildInstallmentsText() }}<br>
                  <span class="amount cost">${{ floatParse(buildInstallmentsAmount()) }}</span>
              </small>

              <small *ngIf="!use_bines && getProductMaxPromotion().installments > 1" style="white-space: normal;">
                <span> {{ getProductMaxPromotion().installments > 1 ? 'Hasta' : '' }}</span>
                <span> {{ getProductMaxPromotion().installments }}</span>
                <span> {{ getProductMaxPromotion().installments === 1 ? 'cuota' : 'cuotas' }}</span>
                <span> {{ getProductMaxPromotion().intereses ? 'fijas' : 'sin interés' }}</span>
                de<br>
                <span class="amount cost"> ${{ floatParse(getProductMaxPromotion().installments_amount) }}</span>
              </small>

            </ng-template>
          </span>
          <span class="sale-price" *ngIf="variant.product.is_on_sale">
            {{ store == 'nuuv' ? 'u$s' : '$'}} {{ floatParse(variant.product.sale_price) }}
          </span>
          <span class="price" [ngClass]="{'sale-price': !variant?.product.is_on_sale }" *ngIf="variant.product.regular_price">
            {{ store == 'nuuv' ? 'u$s' : '$'}} {{ floatParse(variant.product.regular_price) }}
          </span>
          <span class="points-and-price" *ngIf="variant.product.points">
            <hr>
            {{ store == 'Macro' ? floatParse(macroPoints) : floatParse(variant.product.points) }} Puntos {{ store == 'Macro' && variant.product.is_on_sale ? ' + $' + floatParse(salePrice) : ' + $' + floatParse(regularPrice) }}
          </span>
        </div>
        <div #points *ngIf="variant.product.transaction_type === 'voucher' || variant.product.transaction_type === 'points';">
          <span class="points-price" *ngIf="minPoints">{{ onlyVariant ? "" : "Desde" }} {{ floatParse(minPoints) }} Puntos</span>
        </div>
      </div>

    </div>
  </article>

</ng-template>
