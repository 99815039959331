import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

// Services
import { UrlService } from './url.service';
import { HttpService } from './http.service';
import { StoreService } from './store.service';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class RecargaCelularesService {

  constructor(private urlService: UrlService,
              private http: HttpService,
              private storeService: StoreService,
              private authenticationService: AuthenticationService) { }

  notLoggedIn() {
    // if (!this.authenticationService.isLoggedIn()) this.urlService.navigate("https://www.macro.com.ar/bancainternet/");
  }

  recargarCelular( data: any ) {
    const body = {
        exchange: {
        email: data.email.toString(),
        store_id: data.store_id.toString(),
        full_name: data.name,
        document_type: data.document_type,
        document_number: data.document_number,
        phone_company: data.company,
        amount: data.amount.toString(),
        phone_code: data.phone_code.toString(),
        phone_number: data.phone.toString(),
      },
      gateway: {
        name: "recarga_celular"
      }
    }

    return this.http.post('integration/points_exchange', body );

  }

  

}
