import {Injectable} from '@angular/core';
import { Subject, Observable } from "rxjs";
import { Insurance } from "../interfaces/insurance.interface";
import { HttpService } from './http.service';

@Injectable()

export class InsuranceService {
  private insuranceSubject = new Subject<Insurance>();
  public insurance: Insurance;

  constructor(private http: HttpService) { }

  set(form: any) {
    this.insuranceSubject.next(form.value);
    this.insurance = form.value;
  }

  get(): Observable<Insurance> {
    return this.insuranceSubject.asObservable();
  }

  getValue(): Insurance {
    return  this.insurance ? this.insurance : null;
  }

  acredit(params) {
    return this.http.post('insurances', params )
  }

  validateToken(token) {
    return this.http.get('insurances/new/' + token)
  }

}
