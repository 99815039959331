import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { SuggestionsService } from '../../../../services/suggestions.service'
import {Router} from "@angular/router";

@Component({
  selector: 'app-search-bar',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass'],
})
export class SearchComponent implements OnInit {
  @ViewChild('searcher', { static: true }) searcher: ElementRef;
  suggestions: any;
  hiddenSuggestions: any;
  searchText: string = 'hola hola';

  constructor(private router: Router,
              private suggestionsService: SuggestionsService) { }

  ngOnInit() {}

  searchCatalog() {

    this.router.navigate(['/catalog'], {queryParams: {query: this.sanitize_string( this.searcher.nativeElement.value )}});

    this.hideSuggestions();
  }

  sanitize_string(string: string) {

    this.searchText = string.replace(/%/g, '');

    let a = string.replace(/á/g, 'a' );
    let A = a.replace(/Á/g, 'A' );
    let e = A.replace(/é/g, 'e' );
    let E = e.replace(/É/g, 'E' );
    let i = E.replace(/í/g, 'i' );
    let I = i.replace(/Í/g, 'I' );
    let o = I.replace(/ó/g, 'o' );
    let O = o.replace(/Ó/g, 'O' );
    let u = O.replace(/ú/g, 'u' );
    let U = u.replace(/Ú/g, 'U' );
    let enie = U.replace(/ñ/g, 'n' );
    let ENIE = enie.replace(/Ñ/g, 'N');
    let scape = ENIE.replace(/%/g, '' );

    return scape;

  }

  hideSuggestions() {

    this.hiddenSuggestions = this.suggestions;

    this.suggestions = null;
  }

  searchSuggestions(){

    if(!this.hiddenSuggestions) {

      let query = this.sanitize_string(this.searcher.nativeElement.value);

      if (query.length > 3){

        this.suggestionsService.init(query).subscribe(

          (response) => {

            this.suggestions = response;

          }

        );

      }else{

        this.suggestions = null;

      }
    }else{

      this.suggestions = this.hiddenSuggestions;
      this.hiddenSuggestions = null;
    }
  }
}
