import { MatDialog } from "@angular/material/dialog";
import { environment } from "environments/environment";
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
//Models
import { Menu } from '../../../../models/models.model';
//Components
import { TijeComponent } from "../../../dialogs/tije/tije.component";
//Services
import { UrlService } from "../../../../services/url.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-button-link',
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonLinkComponent implements OnInit {
  @Input('menu') menu: Menu;
  env = environment
  constructor(private dialog: MatDialog,
              private urlService: UrlService,
              private router: Router,
            ) {}

  ngOnInit() {
  }

  navigate(e) {
    if (this.menu.isTravel()) {
      if (environment.name === "Avenida") this.dialog.open(TijeComponent).updateSize('790px', '510px');
    } else {
      this.urlService.navigate(this.menu.url);
    }
  }
}
