
export const environment = {
  production: true,
  /* API */
  token: 'rgRSrSyXRKTil1O8-YHk-A',
  endpoint: 'https://api.avenida.com/api/angular_app',
  session: "23de1a9be3f7004c999d6197b40555a8e9a71dbe08f369cf231defca9a19f7e88e57eab99e92634d139f788064e4e7da37383d128b1f95569f34e5c5bedec86a",
  /* Site */
  site_title: 'Avenida.com | Entrá de compras',
  name: 'Avenida',
  domain: 'avenida.com',
  friendly_domain: 'Avenida.com',
  email: 'ayuda@avenida.com',
  phone: null,
  whatsapp: null,
  gateway: null,
  cft: "0.00%",
  checkout_image: '/assets/images/coupon_checkout_avenida_150.png',
  cards: true,
  /* Promos */
  cybermonday: false,
  hotsale: false,
  /* Transaction */
  cart: true,
  oneClick: true,
  installments: 12,
  taxAddress: false,
  google_conversion_id: "",
  google_conversion_label: "",
  google_remarketing_only: false,
  facebook_app_id: "349201448901340",
  installments_without_interest: false,
  dataFiscal: 'http://qr.afip.gob.ar/?qr=z3nQ4Eh8wqt68YM2w_effA,,',
  regret_buy: 'https://avenidacom.zendesk.com/hc/es/requests/new',
  consumer_defense: null, //'https://www.argentina.gob.ar/produccion/defensadelconsumidor/formulario',
  /* Account */
  login: true,
  external_login: false,
  register_form: true,
  checkoutUserValidation: false,    // Key de config para activar la validacion de Renaper y Equifax al momento de realizar la compra
  recovery_password: false,
  reviews: false,
  /* Social */
  social: false,
  instagram: null,
  facebook: null,
  twitter: "avenida_ar",
  /* Layout */
  header_version: 0,
  footer: 0,
  variantCard: 1,
  buyBox: 1,
  checkout_version: 0,
  sizeChart: false,
  imagePreview: false,
  genericCheckoutError: false,
  showRealtedProducts: true,
  /* Geolocation */
  geolocation: false,
  /* Pre load localities and Zip-codes */
  loadZipcodes: false,
zendesk_key:'9ced435b-1480-4150-82c0-bab0c32f0e41',
emblue_code:'',
zoho_url:'',
gtm_id:['GTM-M427CKD'],
};

