// Angular
import { Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// Services
import { RecargaCelularesService } from 'services/recarga-celulares.service';
import { MacroService } from 'services/macro.service';
import { StoreService } from 'services/store.service';
import { PointsService } from 'services/points.service';
import { UrlService } from 'services/url.service';
import { PriceService } from 'services/price.service';
import { ProfileService } from 'services/account/profile.service';
import { AuthenticationService } from 'services/authentication.service';
import { CustomerService } from 'services/customer.service';
//Other
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { Customer } from 'models/models.model';
import { MktService } from '../../new-core/core/services/mkt.service';

@Component({
  selector: 'app-celulares',
  templateUrl: './celulares.component.html',
  styleUrls: ['./celulares.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class CelularesComponent implements OnInit, OnDestroy {

  recargaCelularesForm: FormGroup;
  macroSession: Subscription;

  storeId: number;
  celularesEquivalencia: number;
  points: any;
  customer: Customer;
  date = new Date();

  formInvalid: Boolean;

  recargaData: any;
  formRecarga = false;
  confirmData: Boolean;
  stepsRecargas = true;
  processData: Boolean;
  isLoggedIn: boolean;
  externalLogIn: boolean;
  showPoints: boolean;

  cardTitle: String = '';

  codigoOperacion: String;
  fechaOperacion: Date;
  pointsSpent: any;
  math = Math;

  // Objeto para manejo del
  // status de la recarga
  statusRecarga: any = {
    msg: String,
    details: String,
    loader: Boolean,
    status: String // 'success' o 'failed'
  };

  amounts: any[] = [
    {amount: '10'},
    {amount: '20'},
    {amount: '50'},
    {amount: '100'},
    {amount: '200'},
    {amount: '300'},
    {amount: '400'},
    {amount: '500'}
  ];

  companies: any[] = [
    {value: 'Claro', viewValue: 'Claro'},
    {value: 'Movistar', viewValue: 'Movistar'},
    {value: 'Personal', viewValue: 'Personal'},
    {value: 'Tuenti', viewValue: 'Tuenti'}
  ];

  constructor(private router: Router,
    private _mktService: MktService,
    private urlService: UrlService,
    private formBuilder: FormBuilder,
    private recargaCelularesService: RecargaCelularesService,
    private macroService: MacroService,
    private storeService: StoreService,
    private pointsService: PointsService,
    private profileService: ProfileService,
    private authenticationService: AuthenticationService,
    private customerService: CustomerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.externalLogIn = this.authenticationService.isExternalLogin();

    this.setUpForm();
    this.getStoreData();
    this._mktService.setMetaTags('Recarga Celulares');
    this.points = this.getPoints();

    if (this.isLoggedIn && environment.name === 'Macro') {

      (!this.macroService.isValidSession()) ? this.router.navigate(['session-expired']) : null;

      this.macroSession = this.macroService.sessionStatus().subscribe(
        (response) => {
          (!response) ? this.router.navigate(['session-expired']) : null;
        }
      )

      this.profileService.show().subscribe(
        (response) => {
          this.customer = response;
          this.recargaCelularesForm.patchValue({
            'email': response.email
          });

          (response.temporary_email || !response.doc_number) ? this.macroService.updateProfile() : null ;
        }
      );

    }

    this.route.queryParamMap.subscribe(params => {
      (params.getAll('service')) ? this.patchValueCompany(params.getAll('service')[0]) : null;
    })


  }

  setUpForm() {
    this.recargaCelularesForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      company: ['', [
        Validators.required
      ]],
      phonecode: ['', [
        Validators.required,
        Validators.maxLength(5),
        Validators.minLength(2)
      ]],
      phone: ['', [
        Validators.required
      ]],
      amount: ['', [
        Validators.required
      ]],
    });

    this.recargaCelularesForm.valueChanges.subscribe( () => {
      this.formInvalid = false;
    });
  }

  esCanjeable(monto: number) {
    if ( monto >= this.points ) {
      return true;
    } else {
      return false;
    }
  }

  getStoreData() {
    //this.storeService.currentStore();
    this.storeService.getStore()
      .subscribe(
        store => {
          this.storeId = store.id;
          this.celularesEquivalencia = store.visa_puntos_recargas_equivalence;
          this.showPoints = true;
        });

  }

  getPoints() {
    return this.pointsService.getPoints();
  }

  canjearPuntos() {
    if (this.isLoggedIn) {

      if (this.recargaCelularesForm.valid) {
        this.formRecarga = false;
        // Obtengo datos del form y
        // hago set de los datos de recarga
        const { email, amount, company, phonecode, phone } = this.recargaCelularesForm.value;

        this.recargaData = {
          id_cobis: +this.macroService.getCobis(),
          store_id: this.storeId,
          name: this.customer.first_name + ', ' +  this.customer.last_name,
          document_type: this.customer.doc_type,
          document_number: this.customer.doc_number,
          email: email,
          company: company,
          amount: amount,
          phone_code: phonecode,
          phone: phone
        }

        // Filtro amounts[] para ubicar los puntos gastados
        const montoCarga = this.recargaCelularesForm.value.amount;
        this.pointsSpent = Math.ceil(montoCarga / this.celularesEquivalencia);

        this.confirmData = true;
        this.cardTitle = 'Confirmar canje por recarga de celular';
      } else {
        this.formInvalid = true;
      }

    } else {
      this.macroService.redirectToLogin();
    }

  }

  confirmarCanje() {
    this.confirmData = false;
    this.formRecarga = false;
    this.processData = true;
    this.statusRecarga = {
      msg: 'Su operación está siendo procesada',
      details: '',
      loader: true,
      status: ''
    };

    this.recargaCelularesService.recargarCelular(this.recargaData)
    .subscribe((response) => {

      if (response.success) {

        this.statusRecarga = {
          msg: 'Transacción exitosa',
          details: 'En los próximos minutos recibirás la confirmación en tu correo',
          loader: false,
          status: 'success'
        };

        // Capturo Código y fecha de operación
        this.codigoOperacion = response.order_id;
        this.fechaOperacion = new Date();

        // Actualizo puntos de usuario
        this.pointsService.updatePoints( - Math.ceil(this.pointsSpent));

        //Send event to Google Analytics
        this._mktService.collect('RECARGA-CELULARES', {recarga_celulares_data: this.recargaData})
      } else {

          this.statusRecarga = {
            msg: 'No fue posible completar la transacción',
            details: null,
            loader: false,
            status: 'failed'
          };

      }
    }, (error)=>{
      this.statusRecarga = {
        msg: 'No fue posible completar la transacción',
        details: null,
        loader: false,
        status: 'failed'
      };
    });

  }

  // reset = true Resetea el formulario
  cancelarCanje(reset: Boolean) {
    this.stepsRecargas = true;
    this.formRecarga = false;
    this.confirmData = false;
    this.processData = false;
    this.cardTitle = ''
    if (reset) {this.resetForm()};
  }

  async patchValueCompany( company:string ) {
    if(company) {
      const companyParsed = company.charAt(0).toUpperCase() + company.substring(1);
      this.recargaCelularesForm.patchValue({
        'company': companyParsed
      });
    }
  }

  comenzarRecarga() {
    if (this.isLoggedIn) {
      this.formRecarga = true;
      this.stepsRecargas = false
    } else {
      this.macroService.redirectToLogin();
    }
  }

  resetForm() {
    this.recargaCelularesForm.reset();
  }

  floatParse(attr) {
    return PriceService.humanize(attr);
  }

  ngOnDestroy() {
    if (this.macroSession) {
      this.macroSession.unsubscribe();
    }
  }

}
