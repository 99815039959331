// Angular
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
// Services
import { AuthenticationService } from '../../../services/authentication.service';
import { UserIdleService } from '../../../services/user-inactivity.service';
import { MacroService } from '../../../services/macro.service';
// Other
import { environment } from 'environments/environment';

@Component({
  template: ''
})

export class LogoutComponent implements OnInit  {
  env: any;

  constructor ( private router: Router,
                private authenticationService: AuthenticationService,
                private userIdleService:UserIdleService,
                private macroService: MacroService
              ) { }

  ngOnInit() {
    this.env = environment;
    if (this.authenticationService.isLoggedIn() && this.env.name !== "Macro") { this.authenticationService.logout(); }
    if (this.env.name === "Macro") {
      this.macroService.macroLogout()
      this.router.navigate(['/'])
    };
  }
}
