import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Product} from '../models/models.model';

@Injectable()

export class ProductService {

  constructor(private http: HttpService) {}

  calculateShipping(product: Product, postal_code) {
    return this.http.post('products/calculate_shipping', {product_id: product.id, postal_code: postal_code});
  }


  discountVoucher(type, params) {
    const gateway = {
      "name": "visa_puntos"
    }
    if(type == "points"){
    
      return this.http.post('integration/points_exchange', {exchange: params, gateway: gateway});
    
    } else if (type == "cashback") {
    
      return this.http.post('integration/cashback_points_exchange', {exchange: params, gateway: gateway});
    
    } else {
    
      return this.http.post('integration/voucher_points_exchange', {exchange: params});
    
    }
  }    

}
