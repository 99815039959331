<div class="create-address">

    <div *ngIf="!success">
      <h6 class="my-2">Actualizá tus datos</h6>
      <p class="text-muted mb-3" style="line-height: 22px;">Actualizá tus datos para continuar disfrutando de los beneficios de {{tienda}}.</p>
      <form name="form" [formGroup]="profileForm" (ngSubmit)="submit()">
        <div *ngIf="errors" class="alert alert-danger">
          <p *ngFor="let error of errors">{{ error }}</p>
        </div>
        <div class="form-row">
          
          <div class="form-group col-md-12">
            <label for="email">Email</label>
            <input type="email" class="form-control form-control-sm" [ngClass]="profileForm.controls['email'].errors && !profileForm.controls['email'].pristine ? 'is-invalid' : ''" id="email" formControlName="email">
            <div class="invalid-feedback" *ngIf="profileForm.controls['email'].errors">
              <div [hidden]="!profileForm.controls['email'].errors.required">El E-mail es <strong>requerido</strong>.</div>
              <div [hidden]="!profileForm.controls['email'].errors.email">El E-mail es inválido.</div>
            </div>
          </div>
        
        </div>

        <div class="form-row">
        
          <div class="col-md-4 col-4">
            <div class="form-label-group">
              <label>Tipo Doc.:</label>
              <select formControlName="doc_type" class="form-control form-control-sm" placeholder="Tipo Doc.">
                <option *ngFor="let doc of docTypes" value="{{doc}}">{{ doc }}</option>
              </select>
            </div>
          </div>
          
          <div class="col-md-8 col-8">
            <div class="form-label-group">
              <label>Número de Documento:</label>
              <input formControlName="doc_number" type="text" class="form-control form-control-sm"  placeholder="Número de Documento" [ngClass]="profileForm.controls['doc_number'].errors && profileForm.controls['doc_number'].touched ? 'is-invalid' : ''">
              <div class="invalid-feedback" *ngIf="profileForm.controls['doc_number'].errors?.required">
                Campo requerido.
              </div>
              <div class="invalid-feedback" *ngIf="profileForm.controls['doc_number'].errors?.maxlength">
                Número de documento inválido
              </div>
            </div>
          </div>
        </div>

        <button md-button class="d-block btn btn-sm btn-primary btn-block text-uppercase mt-4">Actualizar</button>
        
      </form>
    </div>


    <div *ngIf="success" class="text-center py-5">
      <i class="fe fe-check-circle mb-3 text-success" style="font-size: 2.5em;"></i>
      <h6 class="my-2 mt-3">Email actualizado con éxito</h6>
      <p class="text-muted mb-3" style="line-height: 22px;">Continua disfrutando de los beneficios de {{tienda}}.</p>
    </div>
    
  </div>
  