import {Component, OnInit, ElementRef, HostListener} from '@angular/core';
import {ComponentLanding} from "../../../../../models/models.model";
import {UrlService} from "../../../../../services/url.service";
import {TijeComponent} from "../../../../dialogs/tije/tije.component";
import { MatDialog } from "@angular/material/dialog";
import { environment} from 'environments/environment'

declare var lory: any;

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.sass']
})
export class SliderComponent implements OnInit {
  component: ComponentLanding;
  multiSlideLory: any;
  mobile: boolean = false;

  static acceptsComponent(cmp: ComponentLanding ): boolean {
    return cmp.type === 'slider';
  }

  constructor(private urlService: UrlService,
              private dialog: MatDialog,
            ) {}

  ngOnInit(): void {
    this.mobile = window.innerWidth >= 425 ? false : true;
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobile = window.innerWidth >= 425 ? false : true;
  }

  getImages(){
    let slides: any[] = [];
    this.component.data.slides.forEach((element) => {
      if (element.image) slides.push(element);
    });
    return slides
  }

  navigate(item) {
    this.urlService.navigate(this.urlService.linkSelector(item));
  }

  travelDialog(){
    if (environment.name === "Avenida") this.dialog.open(TijeComponent).updateSize('790px', '510px');
  }

  isTije() {
    return this.component.data["banner"].title === 'Viajes'
  }

  isDespegar() {
    return this.component.data["banner"].title === 'Despegar'
  }

  isAerolineas() {
    return this.component.data["banner"].title === 'Millas Aerolíneas Plus'
  }

  bannerClick(){
    if(this.isTije()) {
      this.travelDialog()
    } else if (this.isDespegar()) {
      this.navigate(this.component.data["banner"].link);
    } else {
      this.navigate(this.component.data["banner"].link);
    }
  }
}
