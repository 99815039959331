import { Subscription } from 'rxjs';
import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  OnDestroy,
  AfterContentInit,
  ViewEncapsulation
} from '@angular/core';

import { LandingsService } from '../../services/landings.service';
import { Landing } from '../../models/models.model';
import { BaseComponent } from "../pages/landings/base.component";
import { Metatag } from '../../models/models.model';
import { AuthenticationService } from '../../services/authentication.service';
import { MacroService } from '../../services/macro.service';
import { CustomerService } from '../../services/customer.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { MktService } from '../new-core/core/services/mkt.service';

@Component({
  selector: 'app-home',
  templateUrl: '../pages/landings/base.component.html',
  providers: [LandingsService],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {

  landing: Landing;
  env: any;

  private subscriptions: Subscription[] = [];



  // EN EL CASO DE MACRO, LANDING KEY
  // DEFINE A QUE COMPONENTE DE HOME SE REDIRIGE AL USUARIO
  // DEPENDIENDO SI ES SELECT O NO
  landingKey = 'home';

  constructor(private _landingsService: LandingsService,
              private _authService: AuthenticationService,
              private router: Router,
              public componentFactoryResolver: ComponentFactoryResolver,
              private _mktService: MktService,
              private _macroService: MacroService,
              private _customerService: CustomerService) {
    super(componentFactoryResolver)
  }

  ngOnInit() {

    if ( this._authService.isExternalLogin() ) {

      this.router.navigate(['/'])

    }

    this.selectMacroHomeType();

  }

  ngOnDestroy(){
    this.subscriptions.forEach( sub => sub.unsubscribe() );
  }

  selectMacroHomeType() {
        // SI ES MACRO, SE DEFINE EL LANDING KEY
    // Y SE CARGA EL LANDING CORRESPONDIENTE

    if ( environment.name === "Macro" ) {

      if ( this._authService.isLoggedIn() ) {

        this.subscriptions.push(

          this._customerService.init()
            .subscribe(

              res => {

                if (res.is_select_user) {

                  this._macroService.setBodySelectaId(true);

                  this.loginManage('selecta');

                } else {

                  this.loginManage('home');

                }

              },
              error => {

                console.error( error );

              }
            )
        );

      } else {

        this._macroService.setBodySelectaId(false);

        this.loginManage('home');
      }

    } else {

      this.loginManage('home');
    }
  }

  loginManage(page: string) {

    this.subscriptions.push(

      this._landingsService.find(page)
        .subscribe(

          res => {

            this.landing = res;

            const metatags: Metatag = new Metatag;

            metatags.title = this.landing.meta_title;

            metatags.description = this.landing.meta_description;

            metatags.image_src = '';

            this._mktService.setMetaTags(metatags.title, metatags);

            this.componentsViewer.clear();

            for (const component of this.landing.components) {

              this.addComponent(component);
          }

        },
        error => {

          console.error(error);

        }
      )
    );
  }

}
